import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import rootReducer from 'stores/rootReducer';
import { BuildelSocket } from '@buildel/buildel';
import { getCookie } from 'helpers/functions';

import {
  getOfficerChatFinalMessages,
  setAiTyping,
  setStreamingChatMessage,
  WS_CONNECT,
  WS_DISCONNECT,
} from 'stores/chatrooms';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel1,
  blacklist: [
    'me',
    'userDashboard',
    'coDashboard',
    'checkMisconductStatus',
    'users',
    'userDetails',
    'importData',
    'groups',
    'groupDetails',
    'groupUsers',
    'notifications',
    'reports',
    'reportDetails',
    'documentPreview',
    'toastMessages',
    'documentDetails',
    'documentLogs',
    'recipientsGroups',
    'specificUserDocuments',
    'allRecipients',
    'documentEdit',
    'chatrooms',
    'misconductForm',
    'anonymousMisconductForm',
    'officerMisconductForm',
    'newDocumentVersion',
    'documentVersions',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function getComposeFunction() {
  if (process.env.NODE_ENV === 'development') {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
  }

  return compose;
}

const websocketMiddleware = (store) => (next) => {
  let buildel;
  return (action) => {
    const dispatch = store.dispatch;

    switch (action.type) {
      // User request to connect
      case WS_CONNECT:
        buildel = new BuildelSocket(
          store.getState().chatrooms.status.ai_chat_topic.organization_id,
          {
            authUrl: `${process.env.REACT_APP_BUILDEL_AUTH_URL}`,
            socketUrl: `${process.env.REACT_APP_BUILDEL_SOCKET_URL}`,
            headers: {
              'X-CSRFTOKEN': getCookie('csrftoken'),
            },
          }
        );
        buildel.connect().then(() => {
          const topic = store.getState().chatrooms.status.ai_chat_topic;
          if (!topic) return;
          const run = buildel.run(`${topic.workflow_id}:${topic.run_id}`, {
            onBlockOutput: (blockId, outputName, payload) => {
              // eslint-disable-next-line no-console
              console.log(`Output from block ${blockId}, output ${outputName}:`, payload);
              if (!payload || !payload.message || blockId !== 'text_output_1') return;

              dispatch(setStreamingChatMessage(payload.message));
            },
            onBlockStatusChange: (blockId, isWorking) => {
              if (blockId === 'chat_1') dispatch(setAiTyping(isWorking));
              if (blockId === 'webhook_output_1' && !isWorking)
                dispatch(getOfficerChatFinalMessages());
              // eslint-disable-next-line no-console
              console.log(`Block ${blockId} is ${isWorking ? 'working' : 'stopped'}`);
            },
          });
          run.start();
        });

        break;

      // User request to disconnect
      case WS_DISCONNECT:
        buildel.disconnect();
        break;

      default:
        break;
    }

    return next(action);
  };
};

function configureStore() {
  const composeEnhancers = getComposeFunction();
  const middleware = [thunkMiddleware, websocketMiddleware];
  const enhancers = [];
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );
  const persistor = persistStore(store);

  return { store, persistor };
}

export default configureStore;
