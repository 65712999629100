import React from 'react';
import PropTypes from 'prop-types';

import { StyledUserAvatar, avatarSize } from './style';

const propTypes = {
  user: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
  officerStyle: PropTypes.bool,
  aiStyle: PropTypes.bool,
};

const UserAvatar = ({
  user: { first_name, last_name, officer },
  size,
  officerStyle,
  aiStyle,
}) => {
  const firstLastName = first_name + last_name;
  let hash = 0;
  for (let i = 0; i < firstLastName.length; i++) {
    hash = firstLastName.charCodeAt(i) + hash * 31;
    hash = hash & hash;
  }

  const renderOfficerStyle = officerStyle !== undefined ? officerStyle : officer;

  return (
    <StyledUserAvatar
      hue={hash % 360}
      size={size}
      officerStyle={renderOfficerStyle}
      aiStyle={aiStyle}
    >
      {first_name[0] + last_name[0]}
    </StyledUserAvatar>
  );
};

UserAvatar.propTypes = propTypes;

export default UserAvatar;
export { avatarSize };
