import React, { useState } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { generateRouteLink, ROUTING } from 'routing';

import UserDropdown from './UserDropdown';
import Button from 'components/Button';
import InnerText from 'components/InnerText';
import { SimpleModal, CancelButton, PrimaryButton } from 'components/SimpleModal';
import MessagesIcon from 'components/Svg/MessagesIcon';
import NotificationsIcon from 'components/Svg/NotificationsIcon';
import ShieldIcon from 'components/Svg/ShieldIcon';
import UserAvatar, { avatarSize } from 'components/UserAvatar';

import { colors, colorName, deviceSizes } from 'styles';
import { SessionTimeoutTimerWrapper, UserMenu, UserMenuItem } from '../style';
import { messages } from '../messages';
import { globalMessages } from 'translations';
import Typography from 'components/Typography';
import Countdown from 'react-countdown';
import WarningOutlineIcon from 'components/Svg/WarningOutlineIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  DOCUMENTS_MODULE,
  isAuthenticatedOfficer,
  logOutUser,
  MISCONDUCTS_MODULE,
} from 'stores/auth';
import { useInterceptorContext } from 'InterceptorProvider';
import MatchMedia from 'components/MatchMedia';
import ProtectedSection from '../../RolesManagement/ProtectedSetion';

const propTypes = {
  hasOfficerRights: PropTypes.bool,
  hasUnreadMessages: PropTypes.bool,
  userData: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    has_new_notifications: PropTypes.bool,
  }),
  surveyCustomPath: PropTypes.string,
};

const TopMenu = ({ hasOfficerRights, hasUnreadMessages, userData, surveyCustomPath }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalOpen, triggerModal] = useState(false);
  const [isSessionModalOpen, setIsSessionModalOpen] = useState(false);
  const [isDropdownVisible, triggerDropdown] = useState(false);
  const { time } = useInterceptorContext();

  return (
    <>
      <UserMenu>
        <MatchMedia minWidth={deviceSizes.smallScreen}>
          <SessionTimeoutTimerWrapper>
            <Typography size="small">
              {intl.formatMessage(messages.sessionTimeout)}:
            </Typography>
            <Typography size="small" weight="bold" unsetWidth>
              <Countdown
                date={time}
                intervalDelay={0}
                renderer={({ days, hours, minutes, formatted }) => (
                  <span>
                    {minutes + hours * 60 + days * 24 * 60}:{formatted.seconds}
                  </span>
                )}
                onComplete={() => {
                  dispatch(logOutUser());
                }}
                onTick={({ total }) => {
                  if (total === 15000 && !isSessionModalOpen) {
                    setIsSessionModalOpen(true);
                  }
                }}
              />
            </Typography>
          </SessionTimeoutTimerWrapper>
        </MatchMedia>

        {hasOfficerRights ? (
          <ProtectedSection modules={[DOCUMENTS_MODULE]}>
            <UserMenuItem as={NavLink} to={ROUTING.CO_CHATS} isNew={hasUnreadMessages}>
              <MessagesIcon />
            </UserMenuItem>
          </ProtectedSection>
        ) : (
          <ProtectedSection modules={[MISCONDUCTS_MODULE]}>
            <Button primary onClick={() => triggerModal(true)}>
              {intl.formatMessage(messages.reportMisconduct)}
            </Button>
          </ProtectedSection>
        )}

        <ProtectedSection modules={[DOCUMENTS_MODULE, MISCONDUCTS_MODULE]}>
          <UserMenuItem
            as={NavLink}
            to={ROUTING.NOTIFICATIONS}
            color={colors.mediumGray}
            isNew={userData && userData.has_new_notifications}
            aria-label="notifications"
          >
            <NotificationsIcon />
          </UserMenuItem>
        </ProtectedSection>

        <UserMenuItem onClick={() => triggerDropdown((prevState) => !prevState)}>
          <UserAvatar
            size={avatarSize.SMALL}
            user={userData}
            officerStyle={hasOfficerRights}
          />

          <UserDropdown isVisible={isDropdownVisible} />
        </UserMenuItem>
      </UserMenu>

      {isModalOpen && (
        <SimpleModal
          isOpen={isModalOpen}
          closeHandler={() => triggerModal(false)}
          heading={intl.formatMessage(messages.misconductModalTitle)}
          description={
            <FormattedMessage
              {...messages.misconductModalDesc}
              values={{
                emphasis: (
                  <InnerText color={colorName.secondary} weight="bold">
                    {intl.formatMessage(messages.misconductModalEmphasis)}
                  </InnerText>
                ),
              }}
            />
          }
          icon={ShieldIcon}
        >
          <CancelButton onClick={() => triggerModal(false)} />

          <PrimaryButton
            component={Link}
            to={generateRouteLink(ROUTING.SURVEYS_SURVEY, surveyCustomPath)}
          >
            {intl.formatMessage(globalMessages.continue)}
          </PrimaryButton>
        </SimpleModal>
      )}

      {isSessionModalOpen && (
        <SimpleModal
          isOpen={isSessionModalOpen}
          closeHandler={() => setIsSessionModalOpen(false)}
          heading={intl.formatMessage(messages.sessionTimeoutModalHeader)}
          description={<FormattedMessage {...messages.sessionTimeoutModalDescription} />}
          icon={WarningOutlineIcon}
        >
          <PrimaryButton onClick={() => history.go(0)}>
            {intl.formatMessage(globalMessages.refresh)}
          </PrimaryButton>
        </SimpleModal>
      )}
    </>
  );
};

TopMenu.propTypes = propTypes;

const mapStateToProps = (state) => ({
  hasOfficerRights: isAuthenticatedOfficer(state.auth),
  hasUnreadMessages: state.auth.hasUnreadMessages,
  userData: state.auth.userData,
  surveyCustomPath: state.tenantProperties.properties.customPath,
});

export default withRouter(connect(mapStateToProps)(TopMenu));
