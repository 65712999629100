import moment from 'moment';

import api from 'api';
import { history } from 'helpers/appHistory';
import { langCodes } from 'helpers/commonConstants';
import { toastContext, toastType } from 'helpers/toastConstants';
import { ROUTING } from 'routing';
import { setToastMessage } from './toastMessages';
import { getBrowserLanguage, getUserLanguage } from 'helpers/functions';

// Actions
export const SET_USER_AUTHENTICATED = 'auth.SET_USER_AUTHENTICATED';
export const SET_EXTENDED_VIEW_RIGHTS = 'auth.SET_EXTENDED_VIEW_RIGHTS';
export const UNSET_USER_AUTHENTICATED = 'auth.UNSET_USER_AUTHENTICATED';
export const SET_USER_DATA = 'auth.SET_USER_DATA';
export const SET_USER_LANGUAGE = 'auth.SET_USER_LANGUAGE';
export const SET_LOADING = 'auth.SET_LOADING';
export const SET_DOCUMENT_OWNER_RIGHTS = 'auth.SET_DOCUMENT_OWNER_RIGHTS';
export const SET_UNREAD_MESSAGES = 'auth.SET_UNREAD_MESSAGES';
export const SET_MISCONDUCT_ACCESS = 'auth.SET_MISCONDUCT_ACCESS';
export const SET_IS_TOKEN_SENT = 'auth.SET_IS_TOKEN_SENT';
export const RESET_ROOT_REDUCER = 'auth.RESET_ROOT_REDUCER';
export const SET_ERROR = 'auth.SET_ERROR';
export const SET_ROLES = 'auth.SET_ROLES';

// Reducer
export const initialState = {
  userData: null,
  userLanguage: getBrowserLanguage(),
  isAuthenticated: false,
  hasExtendedViewRights: false,
  hasUnreadMessages: false,
  hasOwnedDocuments: false,
  hasOwnedMisconducts: false,
  isTokenSent: false,
  isLoading: false,
  error: {},
  roles: [],
};

// const DUMMY_ROLES = [
//   { role: 'officer', module: 'documents' },
//   { role: 'employee', module: 'documents' },
// ];
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ROLES:
      return { ...state, roles: action.payload };
    // case SET_ROLES:
    //   return { ...state, roles: DUMMY_ROLES };
    case SET_USER_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
      };

    case SET_EXTENDED_VIEW_RIGHTS: {
      return {
        ...state,
        hasExtendedViewRights: action.payload,
      };
    }

    case UNSET_USER_AUTHENTICATED:
      return {
        ...initialState,
        userLanguage: action.payload,
      };
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case SET_USER_LANGUAGE:
      return {
        ...state,
        userLanguage: action.payload,
      };
    case SET_DOCUMENT_OWNER_RIGHTS:
      return {
        ...state,
        hasOwnedDocuments: action.payload,
      };
    case SET_UNREAD_MESSAGES:
      return {
        ...state,
        hasUnreadMessages: action.payload,
      };
    case SET_MISCONDUCT_ACCESS:
      return {
        ...state,
        hasOwnedMisconducts: action.payload,
      };
    case SET_IS_TOKEN_SENT:
      return {
        ...state,
        isTokenSent: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    default:
      return state;
  }
}

// Action Creators
export function setUserAuthenticated() {
  return { type: SET_USER_AUTHENTICATED };
}

export function setExtendedViewRights(bool) {
  return { type: SET_EXTENDED_VIEW_RIGHTS, payload: bool };
}

export function setUserData(data) {
  return { type: SET_USER_DATA, payload: data };
}

export function setUserLanguage(locale) {
  return { type: SET_USER_LANGUAGE, payload: locale };
}

export function unsetUserAuthenticated(langCode) {
  return { type: UNSET_USER_AUTHENTICATED, payload: langCode };
}

export function setDocumentOwnerRight(owner) {
  return { type: SET_DOCUMENT_OWNER_RIGHTS, payload: owner };
}

export function setUnreadMessages(bool) {
  return { type: SET_UNREAD_MESSAGES, payload: bool };
}

export function setMisconductAccess(bool) {
  return { type: SET_MISCONDUCT_ACCESS, payload: bool };
}

export function setIsTokenSent(bool) {
  return { type: SET_IS_TOKEN_SENT, payload: bool };
}

export function setError(error) {
  return { type: SET_ERROR, payload: error };
}

export function setRoles(roles) {
  return { type: SET_ROLES, payload: roles };
}

export const setLoading = (payload) => ({ type: SET_LOADING, payload });

export const resetRootReducer = () => ({ type: RESET_ROOT_REDUCER });

// Side Effects
export function logOutUser(scope) {
  return async (dispatch, getState) => {
    const langCode = getUserLanguage(getState().auth.userLanguage);
    try {
      api.abortController.abort();
      await api.auth.signOut(scope);
    } catch (error) {
      // @todo - update error handling
    } finally {
      dispatch(setExtendedViewRights(false));
      if (!scope) {
        dispatch(unsetUserAuthenticated(langCode));
      }
    }
  };
}

export function logOutUserAndClearSession(apiClient = api) {
  return async (dispatch) => {
    try {
      apiClient.abortController.abort();
      await apiClient.auth.signOut();
    } catch (error) {
      // @todo - update error handling
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      dispatch(resetRootReducer());
    }
  };
}

export function passwordExpired() {
  return async (dispatch) => {
    history.push(ROUTING.ACCOUNT);

    setTimeout(
      () =>
        dispatch(
          setToastMessage({
            content: {
              translationKey: 'passwordExpired',
            },
            context: toastContext.ACCOUNT,
            type: toastType.ALERT,
          })
        ),
      600
    );
  };
}

export const updateUserLanguage = (language) => async (dispatch) => {
  try {
    const lang = getUserLanguage(language);
    await api.me.updateLanguage(lang);
    dispatch(setUserAndAppLanguage(lang));
  } catch (error) {
    // @todo - update error handling
  }
};

export const logInUser =
  ({ login, password, scope = 'employee' }) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));

    try {
      const response = await api.auth.signIn({ email: login, password, scope });
      const user = response.data;

      if (!user.language) {
        dispatch(updateUserLanguage(getState().auth.userLanguage));
      }

      dispatch(setRoles(user.roles));
      dispatch(setUserData(user));
      dispatch(setDocumentOwnerRight(user.has_owned_document));
      dispatch(setUserAuthenticated());
      dispatch(setUserAndAppLanguage(user.language));
      dispatch(setExtendedViewRights(user.has_extended_view_rights));
      dispatch(setUnreadMessages(user.has_new_messages));
      dispatch(setMisconductAccess(user.has_misconducts));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getOfficerToken = (email) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    await api.auth.getAuthToken(email);

    dispatch(setIsTokenSent(true));
  } catch {
    // @todo: Add Error handling
  } finally {
    dispatch(setLoading(false));
  }
};

export const logInViaToken = (data) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  dispatch(setError({}));

  try {
    const response = await api.auth.logInViaToken(data);
    const user = response.data;

    if (!user.language) {
      dispatch(updateUserLanguage(getState().auth.userLanguage));
    }
    dispatch(setRoles(user.roles));
    dispatch(setUserData(user));
    dispatch(setDocumentOwnerRight(user.has_owned_document));
    dispatch(setUserAuthenticated());
    dispatch(setUserAndAppLanguage(user.language));
    dispatch(setExtendedViewRights(user.has_extended_view_rights));
    dispatch(setUnreadMessages(user.has_new_messages));
    dispatch(setMisconductAccess(user.has_misconducts));

    dispatch(setIsTokenSent(false));
  } catch (error) {
    // dispatch(setError(error));
    return Promise.reject();
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMyDetails = () => async (dispatch) => {
  try {
    const { data } = await api.me.getMyInfo();
    dispatch(setUserData(data));
    dispatch(setDocumentOwnerRight(data.has_owned_document));
    dispatch(setUnreadMessages(data.has_new_messages));
    dispatch(setMisconductAccess(data.has_misconducts));
    // @todo: Dispatch has misconducts
  } catch {
    // @todo: Add Error handling
  }
};

export const setUserAndAppLanguage = (language) => async (dispatch) => {
  if (language && language !== langCodes.en) {
    await import(`moment/locale/${language}`);
  }

  moment.locale(language || langCodes.en);

  dispatch(setUserLanguage(language));
};

export const handleSessionTimeout = () => {
  return async (dispatch, getState) => {
    if (isAuthenticatedOfficer(getState().auth)) {
      dispatch(setExtendedViewRights(false));
      history.push(ROUTING.CO_SESSION_TIMEOUT);
    } else {
      dispatch(resetRootReducer());
      history.push(ROUTING.EMPLOYEE_SESSION_TIMEOUT);
    }
  };
};

export const handleForbidden = () => {
  return () => {
    history.push(ROUTING.UNAUTHORIZED);
  };
};

export const DOCUMENTS_MODULE = 'documents';
export const MISCONDUCTS_MODULE = 'misconducts';
export const OFFICER_ROLE = 'officer';
export const EMPLOYEE_ROLE = 'employee';

export const filterRoles = (userRoles, roles = []) => {
  return (userRoles || []).filter((role) => (roles || []).includes(role.role));
};

export const getOfficerRoles = (roles) => {
  return filterRoles(roles, [OFFICER_ROLE]);
};

export const getEmployeeRoles = (roles) => {
  return filterRoles(roles, [EMPLOYEE_ROLE]);
};

export const hasRoleRights = (userRoles, modules) => {
  return userRoles.some((role) => (modules || []).includes(role.module));
};

export const isOfficer = (auth) => {
  const { roles } = auth;

  return getOfficerRoles(roles).length > 0;
};

export const isEmployee = (auth) => {
  const { roles } = auth;

  return getEmployeeRoles(roles).length > 0;
};

export const isAuthenticatedOfficer = (auth) => {
  const { hasExtendedViewRights } = auth;

  return isOfficer(auth) && hasExtendedViewRights;
};

export const isAuthenticatedEmployee = (auth) => {
  const { hasExtendedViewRights } = auth;

  return isEmployee(auth) && hasExtendedViewRights;
};

export const hasMisconductsRights = (userRoles) => {
  return hasRoleRights(userRoles, [MISCONDUCTS_MODULE]);
};

export const hasDocumentsRights = (userRoles) => {
  return hasRoleRights(userRoles, [MISCONDUCTS_MODULE]);
};

export const hasOfficerRightsInModule = (userRoles, module) => {
  const officerRoles = getOfficerRoles(userRoles);

  return hasRoleRights(officerRoles, [module]);
};

export const areModulesEnabled = (modules, enabledModules) => {
  return modules.some((module) => (enabledModules || []).includes(module));
};

export const isMisconductsEnable = (modules) => {
  return areModulesEnabled([MISCONDUCTS_MODULE], modules);
};
