import React from 'react';
import PropTypes from 'prop-types';

import { getDateStr } from 'helpers';

import UserAvatar, { avatarSize } from 'components/UserAvatar';

import { MessageWrapper, ContentWrapper, Text, Time } from '../style';
import AIAvatar from 'components/AIAvatar';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { messages } from '../messages';

const propTypes = {
  message: PropTypes.object,
  isOutcoming: PropTypes.bool,
  isDark: PropTypes.bool,
  fileUrlResolver: PropTypes.func,
  roomId: PropTypes.string,
  whistleblowerPerspective: PropTypes.bool,
};

const DocumentLinkMessage = ({
  message: { user, text, created_at },
  isOutcoming,
  isDark,
}) => {
  const ref_documents = JSON.parse(text);

  return (
    <MessageWrapper isOutcoming={isOutcoming}>
      {user.id === 'AI' ? (
        <AIAvatar size={avatarSize.MEDIUM} />
      ) : (
        <UserAvatar size={avatarSize.MEDIUM} user={user} />
      )}

      <ContentWrapper isOutcoming={isOutcoming}>
        <Text isOutcoming={isOutcoming} isDark={isDark}>
          <FormattedMessage {...messages.filesUsedForAnswer} />
          <br />
          {ref_documents.map((file) => (
            <>
              <NavLink key={file.id} to={`/documents/preview/${file.id}`} target="_blank">
                {file.name}
              </NavLink>
              <br />
            </>
          ))}
        </Text>

        <Time isDark={isDark}>{getDateStr(created_at, 'MMM DD, YYYY HH:mm')}</Time>
      </ContentWrapper>
    </MessageWrapper>
  );
};

DocumentLinkMessage.propTypes = propTypes;

export default DocumentLinkMessage;
