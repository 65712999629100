import React from 'react';
import PropTypes from 'prop-types';
import { MessageWrapper, ContentWrapper, ThreadWillEndWrapper } from '../style';
import ClockIcon from 'components/Svg/ClockIcon';
import { FormattedMessage } from 'react-intl.macro';
import Countdown from 'react-countdown';
import { messages } from '../messages';
import { useSelector } from 'react-redux';
import moment from 'moment';

const propTypes = {
  lastMessageDate: PropTypes.string,
};

const ThreadWillEndMessage = ({ lastMessageDate }) => {
  const { status } = useSelector((state) => state.chatrooms);

  return status?.ai_chat_topic?.topic_open_until &&
    lastMessageDate &&
    moment(new Date()).diff(lastMessageDate, 'seconds') > 20 &&
    moment(status.ai_chat_topic.topic_open_until).isAfter(new Date()) ? (
    <MessageWrapper isOutcoming={true}>
      <ContentWrapper isOutcoming={true}>
        <ThreadWillEndWrapper>
          <ClockIcon />
          <p>
            <FormattedMessage {...messages.threadWillEndIn} />
          </p>
          <Countdown
            date={status.ai_chat_topic.topic_open_until}
            renderer={({ days, hours, minutes, formatted }) => (
              <span>
                {days} d {hours} h {minutes} m {formatted.seconds} s
              </span>
            )}
          />
        </ThreadWillEndWrapper>
      </ContentWrapper>
    </MessageWrapper>
  ) : (
    <></>
  );
};

ThreadWillEndMessage.propTypes = propTypes;

export default ThreadWillEndMessage;
