import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
  SORT_DIRECTION_ASCENDING,
} from 'helpers/sharedConstants';
import { setToastMessage } from './toastMessages';
import { toastContext, toastType } from '../helpers/toastConstants';

const DEFAULT_SORT_PARAMS = { key: 'name', direction: SORT_DIRECTION_ASCENDING };

// Actions
export const STORE_CATEGORIES_LIST = 'categories.STORE_CATEGORIES_LIST';
export const STORE_CATEGORIES_LIST_META = 'categories.STORE_CATEGORIES_LIST_META';
export const SET_LOADING = 'categories.SET_LOADING';
export const RESET_CATEGORIES_LIST = 'categories.RESET_CATEGORIES_LIST';

// Reducer
const initialState = {
  categoryList: [],
  isLoading: true,
  categoryListMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
    sortParams: DEFAULT_SORT_PARAMS,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_CATEGORIES_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };
    case STORE_CATEGORIES_LIST_META:
      return {
        ...state,
        categoryListMeta: action.payload,
      };
    case RESET_CATEGORIES_LIST:
      return initialState;
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function storeCategories(data) {
  return { type: STORE_CATEGORIES_LIST, payload: data };
}

export function storeCategoriesMeta(data) {
  return { type: STORE_CATEGORIES_LIST_META, payload: data };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

export function resetCategoriesList() {
  return { type: RESET_CATEGORIES_LIST };
}

// Side Effects
export function getCategories({
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
  sortParams = DEFAULT_SORT_PARAMS,
  query,
}) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return api.categories
      .getCategories({
        page,
        perPage,
        sortBy: sortParams?.key,
        sortOrder: sortParams?.direction,
        query,
      })
      .then((res) => {
        dispatch(storeCategories(res.data.items));
        dispatch(
          storeCategoriesMeta({
            page: res.data.current_page,
            perPage,
            totalPages: res.data.pages,
            sortParams,
          })
        );
      })
      .catch(() => {
        //todo handle error
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function addCategory(name) {
  return () => {
    return api.categories.addCategory(name);
  };
}

export function updateCategory(id, data) {
  return () => {
    return api.categories.editCategory(id, data);
  };
}

export function deleteCategory(id) {
  return (dispatch) => {
    return api.categories.deleteCategory(id).then(() => {
      setToastMessage({
        content: { translationKey: 'categoryDeletedSuccess' },
        context: toastContext.CATEGORIES_MANAGEMENT,
        type: toastType.SUCCESS,
      })(dispatch);
    });
  };
}
