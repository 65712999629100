import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { useIntl } from 'react-intl';

import {
  isAuthenticatedOfficer,
  isMisconductsEnable,
  updateUserLanguage,
} from 'stores/auth';
import { generateRouteLink, resolveDashboardRoute, ROUTING } from 'routing';

import EntryWrapper from 'components/EntryWrapper';
import Typography from 'components/Typography';
import Button from 'components/Button';
import LoginHeader from 'components/LoginHeader';
import LanguageSelectBanner from 'components/LanguageSelectBanner';

import { MainContent, PrimaryButton, StyledLink } from './style';
import { globalMessages } from 'translations';
import { colors } from 'styles';
import { arelanguagesFromMisconducts } from 'helpers/functions';
import { messages } from './messages';
import { Link } from 'react-router-dom';
import { useCustomConfig } from 'helpers/hooks';
import LandingHeader from './LandingHeader';

export const LandingPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    properties: { misconductLanguages, customConfig, customPath, modules },
  } = useSelector((state) => state.tenantProperties);
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, userLanguage, isLoading } = auth;

  const hasOfficerRights = isAuthenticatedOfficer(auth);

  const { hideCheckMisconductStatus } = useCustomConfig();
  const [selectedLanguage, setLanguage] = useState();

  const selectLanguage = (selectedLanguage) => {
    setLanguage({ selectedLanguage });
  };

  const confirmLanguageSelection = () => {
    dispatch(updateUserLanguage(selectedLanguage));
  };

  const dashboardRoute = resolveDashboardRoute(hasOfficerRights);

  if (isAuthenticated) {
    if (userLanguage) return <Redirect to={dashboardRoute} />;
    return (
      <LanguageSelectBanner
        availableLanguages={
          arelanguagesFromMisconducts(customConfig) ? misconductLanguages : undefined
        }
        selectedLanguage={selectedLanguage}
        changeHandler={selectLanguage}
        isLoading={isLoading}
        onConfirmationClick={confirmLanguageSelection}
        description={intl.formatMessage(globalMessages.chooseLanguage)}
      />
    );
  }

  if (modules && !isMisconductsEnable(modules)) {
    return <Redirect to={ROUTING.LOGIN} />;
  }

  return (
    <EntryWrapper withFooter>
      <LoginHeader />
      <MainContent>
        <LandingHeader />

        <PrimaryButton
          component={Link}
          to={generateRouteLink(ROUTING.SURVEYS_SURVEY, customPath)}
          primary
          isFluid
          data-testid="add-new-report-button"
        >
          {intl.formatMessage(messages.reportMisconduct)}
        </PrimaryButton>

        {!hideCheckMisconductStatus && (
          <Button
            component={Link}
            to={ROUTING.CHECK_STATUS}
            secondary
            isFluid
            data-testid="check-report-button"
          >
            {intl.formatMessage(messages.checkMisconductStatus)}
          </Button>
        )}
      </MainContent>
      <StyledLink href={ROUTING.LOGIN}>
        <Typography
          size="small"
          textAlign="center"
          weight="bold"
          color={colors.darkestGray}
          maxWidth={280}
        >
          {intl.formatMessage(messages.goToLoginButton)}
        </Typography>
      </StyledLink>
    </EntryWrapper>
  );
};

export default LandingPage;
