import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  emptyState: {
    id: 'Chat.emptyState',
    defaultMessage: 'You have not started the conversation yet.',
    description: 'Chat empty state placeholder',
  },
  sendMessageButton: {
    id: 'Chat.sendMessageButton',
    defaultMessage: 'Send message',
    description: 'Chat send message button label',
  },
  chatPlaceholder: {
    id: 'Chat.chatPlaceholder',
    defaultMessage: 'Type your message here...',
    description: 'Chat placeholder label',
  },
  threadEnded: {
    id: 'Chat.threadEnded',
    defaultMessage: 'thread ended',
    description: 'Chat placeholder label',
  },
});
