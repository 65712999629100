import React from 'react';
import PropTypes from 'prop-types';
import { WelcomeMessageWrapper } from '../style';

const propTypes = {
  message: PropTypes.string,
};

const WelcomeMessage = ({ message }) => {
  return <WelcomeMessageWrapper>{message}</WelcomeMessageWrapper>;
};

WelcomeMessage.propTypes = propTypes;

export default WelcomeMessage;
