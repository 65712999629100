import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { isMisconductsEnable, logOutUserAndClearSession } from 'stores/auth';
import { fetchTenantProperties } from 'stores/tenantProperties';
import {
  clearFormForAnonymousUser,
  initializeFormForAnonymousUser,
} from 'stores/anonymousMisconductForm';

import LanguageSelectBanner from 'components/LanguageSelectBanner';
import StepsGenerator from 'components/MisconductFormParts/StepsGenerator';

import { useRecaptchaContext } from 'components/Recaptcha/UseRecaptchaContext';

import { messages } from './messages';
import { useApi } from 'api';
import { ROUTING } from '../../../../routing';

const MisconductForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.anonymousMisconductForm);
  const {
    properties: { misconductLanguages, modules },
  } = useSelector((state) => state.tenantProperties);
  const { formStep } = useSelector((state) => state.misconductForm);
  const { userLanguage } = useSelector((state) => state.auth);
  const { api } = useApi();
  const useRecaptcha = useRecaptchaContext();

  const executeRecaptcha = useRecaptcha(userLanguage);

  useEffect(() => {
    dispatch(fetchTenantProperties(userLanguage, api));
  }, [dispatch, userLanguage, api]);

  useEffect(() => {
    dispatch(logOutUserAndClearSession(api));

    return () => dispatch(clearFormForAnonymousUser());
  }, [dispatch, api]);

  const handleLanguageConfirmation = () => {
    dispatch(initializeFormForAnonymousUser(executeRecaptcha, api));
  };

  if (modules && !isMisconductsEnable(modules)) {
    return <Redirect to={ROUTING.LOGIN} />;
  }

  return (
    <>
      {!formStep && (
        <LanguageSelectBanner
          availableLanguages={misconductLanguages}
          selectedLanguage={userLanguage}
          isLoading={isLoading}
          onConfirmationClick={handleLanguageConfirmation}
          description={intl.formatMessage(messages.chooseLanguage)}
          isMisconductForm
        />
      )}

      {formStep && <StepsGenerator step={formStep} />}
    </>
  );
};

export default MisconductForm;
