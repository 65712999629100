import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  emptyState: {
    id: 'Chat.emptyState',
    defaultMessage: 'You have not started the conversation yet.',
    description: 'Chat empty state placeholder',
  },
  sendMessageButton: {
    id: 'Chat.sendMessageButton',
    defaultMessage: 'Send message',
    description: 'Chat send message button label',
  },
  chatPlaceholder: {
    id: 'Chat.chatPlaceholder',
    defaultMessage: 'Type your message here...',
    description: 'Chat placeholder label',
  },
  threadEnded: {
    id: 'Chat.threadEnded',
    defaultMessage: 'thread ended',
    description: 'Chat placeholder label',
  },
  threadWillEndIn: {
    id: 'CoChats.threadWillEndIn',
    defaultMessage: 'The thread will end in: ',
    description: 'Join the conversation alert',
  },
  welcomeMessage: {
    id: 'CoChats.welcomeMessage',
    defaultMessage:
      'Welcome to our Compliance Chat. We appreciate your dedication to upholding ethical standards in our corporation. For a prompt and efficient start, the initial phase of our conversation will be facilitated by our AI chat system. Please share the details of the observed irregularities, and the AI will assist in collecting relevant information.',
    description: 'Welcome message',
  },
  filesUsedForAnswer: {
    id: 'CoChats.filesUsedForAnswer',
    defaultMessage: 'Files used for the answer:',
    description: 'File source message text',
  },
});
