import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { rem, rgba } from 'polished';

import CompanyLogo from 'components/CompanyLogo';
import CompanyLogoSmall from 'components/CompanyLogo/CompanyLogoSmall';
import { toastType } from 'helpers/toastConstants';
import { Column, Row } from 'components/Table/style';
import { StyledButton } from 'components/Button/style';
import { Table } from 'components/ManagementTable/style';

import {
  breakpoints,
  colorName,
  colors,
  font,
  globalSize,
  transitions,
  variables,
} from 'styles';

export const LEFT_COLUMN_XS_PADDING = globalSize.defaultPadding;
export const LEFT_COLUMN_MD_PADDING = globalSize.defaultPadding * 2;

const DROPDOWN_ICON_SIZE = 8;
const DOUBLE_PADDING = globalSize.defaultPadding * 2;

// Motion
export const appearFromTop = keyframes`
  from {
    transform: translateY(${rem(-12)});
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Report Misconduct Sections
export const LoginMiddleSectionForm = styled.form`
  margin: 0 0 4vh;

  @media ${breakpoints.mediumScreen} {
    width: 100%;
    max-width: ${rem(360)};
    margin: ${rem(140)} auto 0 auto;
  }

  ${StyledButton} {
    &:not(:last-child) {
      margin: 0 0 ${rem(16)};
    }
  }
`;

export const StyledLabel = styled.label`
  display: block;
  margin: 0 0 ${rem(12)};
  color: ${({ isDark }) => (isDark ? colorName.white : colorName.black)};
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.small)};
`;

export const HeaderLogo = styled(CompanyLogoSmall)`
  display: block;
  width: ${({ isRestricted }) => (isRestricted ? rem(100) : rem(40))};
  max-height: ${({ isRestricted }) => (isRestricted ? rem(100) : rem(40))};
  margin: 0 auto ${rem(DOUBLE_PADDING)};

  @media ${breakpoints.smallScreen} {
    margin: ${({ isCentered }) =>
      isCentered ? `0 auto ${rem(DOUBLE_PADDING)}` : `0 0 ${rem(DOUBLE_PADDING)}`};
  }
`;

export const SectionStyles = css`
  margin: 0 0 ${rem(32)} 0;
  padding: 0 0 ${rem(24)} 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.grayish};
  }

  @media ${breakpoints.smallScreen} {
    margin: 0 0 ${rem(48)} 0;
    padding: 0 0 ${rem(32)} 0;
  }
`;

export const SingleColumnWrapper = styled.div`
  flex: 1 1 auto;
  width: 100%;
  height: ${({ isFullscreen }) => (isFullscreen ? '100vh' : '100%')};
  padding: ${rem(globalSize.defaultPadding * 1.5)} ${rem(LEFT_COLUMN_XS_PADDING)};
  background: ${({ isWhiteBackground }) =>
    isWhiteBackground ? colorName.white : colorName.mainBackground};

  ${({ isFullscreen }) =>
    isFullscreen &&
    `
    ${LeftColumnContainer} {
      @media ${breakpoints.tablet} {
        margin: 0 auto;
      }
    }
  `};

  @media ${breakpoints.tablet} {
    padding: ${rem(LEFT_COLUMN_MD_PADDING)};
  }

  @media ${breakpoints.smallScreen} {
    padding: ${rem(globalSize.defaultPadding * 2.5)} ${rem(DOUBLE_PADDING)};
  }

  @media ${breakpoints.mediumScreen} {
    overflow: hidden;
  }
`;

export const LeftColumnContainer = styled.div`
  width: 100%;
  max-width: ${rem(globalSize.leftColumnMaxWidth)};
  margin: 0 auto;

  @media ${breakpoints.tablet} {
    margin: 0;
  }
`;

// Select Dropdown Indicator
export const DropdownIndicator = css`
  width: ${rem(DROPDOWN_ICON_SIZE)};
  height: ${rem(DROPDOWN_ICON_SIZE)};
  border: 1px solid ${({ isDark }) => (isDark ? colorName.white : colors.darkGray)};
  border-top: none;
  border-left: none;
  transform: translateY(-50%) rotate(45deg);
`;

// Toast, Alerts, Notification shared styles
export const typeToColor = {
  [toastType.SUCCESS]: colorName.success,
  [toastType.INFO]: colorName.info,
  [toastType.WARNING]: colorName.warning,
  [toastType.ALERT]: colorName.error,
};

export const AlertBase = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 ${rem(8)};
  color: ${colorName.black};
  border-radius: ${rem(variables.borderRadius)};
  animation: ${appearFromTop} 0.5s ${transitions.spring};
`;

export const NotificationMessageWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  margin: 0 0 ${({ marginBottom }) => marginBottom && rem(marginBottom)};
  font-weight: ${({ isNew }) => (isNew ? 'bold' : 'normal')};
`;

export const NotificationIconWrapper = styled.div`
  display: flex;
  flex: 0 0 ${rem(20)};
  align-items: center;
  align-self: self-start;
  justify-content: center;
  height: ${rem(20)};
  margin: 0 ${rem(6)} 0 0;
  color: ${({ type }) => type && typeToColor[type]};

  & > svg {
    display: block;
    width: ${rem(24)};
    height: ${rem(24)};
  }

  @media ${breakpoints.tablet} {
    flex: 0 0 ${rem(24)};
    height: ${rem(24)};
  }
`;

export const NotificationMessage = styled.div`
  color: ${colorName.darkestGray};
  font-size: ${rem(font.size.tiny)};
  line-height: 1.3;

  @media ${breakpoints.tablet} {
    font-size: ${rem(font.size.small)};
  }
`;

export const ToastNotificationBar = styled.div`
  ${AlertBase};
  background: ${colorName.white};
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);

  @media ${breakpoints.tablet} {
    margin: 0 0 ${rem(globalSize.defaultPadding * 1.5)};
  }
`;

export const ToastNotificationIconWrapper = styled.div`
  display: flex;
  flex: 0 0 ${rem(32)};
  align-items: center;
  justify-content: center;
  height: ${rem(32)};
  margin: 0 ${rem(16)} 0 0;
  color: ${colorName.white};
  background: ${({ type }) => type && typeToColor[type]};
  border-radius: ${rem(variables.borderRadius)};

  & > svg {
    display: block;
    width: ${rem(23)};
    height: ${rem(23)};
  }

  @media ${breakpoints.tablet} {
    flex: 0 0 ${rem(45)};
    width: ${rem(45)};
    height: ${rem(45)};
  }
`;

export const ToastNotificationButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  flex: 0 0 ${rem(32)};
  align-items: center;
  justify-content: center;
  height: ${rem(20)};
  margin: 0;
  padding: 0;
  color: ${({ type }) => type && typeToColor[type]};
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: transform ${transitions.uiTiming} ${transitions.spring};
  appearance: none;

  & > svg {
    display: block;
    width: ${rem(12)};
    height: ${rem(12)};
  }

  &:hover {
    transform: scale(1.2);
  }

  @media ${breakpoints.tablet} {
    flex: 0 0 ${rem(40)};
    height: ${rem(28)};
  }
`;

export const CenteredContent = styled.div`
  text-align: center;
`;

export const SelectWithButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 0 ${rem(44)} 0;

  ${StyledButton} {
    margin: ${rem(22)} 0 0 ${rem(24)};
  }
`;

const rowRadius = rem(variables.borderRadius * 1.5);

export const TableBody = styled.tbody`
  display: block;
  width: 100%;

  ${Row} {
    background: ${colorName.white};
  }

  @media ${breakpoints.mediumScreen} {
    display: table-row-group;

    ${Row} {
      height: ${rem(40)};
      background: transparent;
    }

    ${Column} {
      padding: 0 ${rem(8)};
      background: ${colorName.white};

      &:first-child {
        border-radius: ${rowRadius} 0 0 ${rowRadius};
      }

      &:last-child {
        border-radius: 0 ${rowRadius} ${rowRadius} 0;
      }

      &:not(:first-child) {
        border-left: 0;
      }

      &:not(:last-child) {
        border-right: 0;
      }

      &:first-child:last-child {
        border-radius: ${rowRadius};
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  display: inline-block;
  margin: 0 ${rem(3)};
  color: #188ce5;
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.small)};
  text-decoration: none;
`;

// Table shared styles
export const GroupsTooltip = styled.div`
  position: absolute;
  top: ${rem(24)};
  left: 50%;
  padding: ${rem(globalSize.defaultPadding)};
  background: ${colorName.white};
  border-radius: ${rem(variables.borderRadius)};
  box-shadow: 0 ${rem(16)} ${rem(32)} ${rgba(colors.darkestGray, 0.2)};
  transform: translate(-50%, 10%);
  opacity: 0;
  transition-timing-function: ${transitions.spring};
  transition-duration: 0.2s;
  transition-property: opacity, transform;
  pointer-events: none;
`;

export const AdditionalGroupTrigger = styled.div`
  position: relative;
  display: block;
  margin: 0;
  line-height: 1;
  user-select: none;

  ${GroupsTooltip} {
    & > * {
      &:not(:last-child) {
        margin: 0 0 ${rem(8)};
      }
    }
  }

  &:hover {
    ${GroupsTooltip} {
      z-index: 10;
      transform: translate(-50%, 0);
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const PillsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TableWrapper = styled.div`
  margin: 0 0 ${rem(8)} 0;
  padding: 0 ${rem(16)};
  background-color: ${({ gray }) => gray && colors.lightGray};
  border: 1px solid ${colors.grayish};
  border-radius: ${rem(variables.borderRadius)};

  ${Table} {
    &:not(:last-child) {
      margin: 0 0 ${rem(30)} 0;
      border-bottom: 1px solid ${colors.grayish};
    }
  }
`;

// 404
export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${rem(768)};
  height: 100vh;
  min-height: 40vh;
  margin: 0 auto;
  padding: ${rem(16)};

  @media ${breakpoints.tablet} {
    height: 90%;
    margin: ${rem(16)} auto 0;
  }
`;

export const NotFoundButtons = styled.div`
  ${StyledButton} {
    display: flex;

    &:not(:last-child) {
      margin: 0 0 ${rem(16)};
    }
  }

  @media ${breakpoints.smallScreen} {
    ${StyledButton} {
      display: inline-flex;
      width: auto;

      &:not(:last-child) {
        margin: 0 ${rem(16)} 0 0;
      }
    }
  }
`;

export const NotFoundLogo = styled(CompanyLogo)`
  display: block;
  width: ${rem(36)};
  height: ${rem(24)};
  margin: 0 auto ${rem(76)};

  @media ${breakpoints.smallScreen} {
    margin: 0 0 ${rem(76)};
  }
`;

export const DashboardNotificationWrapper = styled.div`
  display: flex;
  gap: ${rem(8)};
  align-items: center;
  justify-content: space-between;
  margin: 0 0 ${rem(8)};
  padding: 0.7rem 1rem;
  color: ${colorName.black};
  background: ${colorName.white};
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: ${rem(8)};
  box-shadow: 0 1rem 3rem 0 rgba(88, 115, 123, 0.08);
  animation: ${appearFromTop} 0.5s ${transitions.spring};

  @media ${breakpoints.tablet} {
    margin: 0 0 ${rem(16)};
  }
`;

export const DashboardNotificationMessageWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  gap: ${rem(12)};
  align-items: flex-start;
  margin: 0 0 ${({ marginBottom }) => marginBottom && rem(marginBottom)};
`;

export const DashboardNotificationMessage = styled.div`
  max-width: 670px;
  color: ${colorName.black};
  font-weight: 400;
  font-size: ${rem(font.size.small)};
  line-height: 1.3;
  @media ${breakpoints.tablet} {
    font-size: ${rem(15)};
  }
`;

export const DashboardNotificationCloseButton = styled.button`
  display: flex;
  gap: ${rem(6)};
  align-items: center;
  padding: ${rem(6)} ${rem(8)};
  color: ${colors.darkestGray};
  font-weight: 600;
  font-size: 13px;
  background-color: ${colorName.white};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: ${rem(4)};
  outline: 0;
  cursor: pointer;
  transition: border, color, background ${transitions.uiTiming} ${transitions.ease};

  &:focus {
    box-shadow: 0px 0px 0px 2px ${colors.darkYellow};
  }
  &:hover {
    color: ${colorName.white};
    background: ${colorName.confidentGrey};
  }
`;

const DashboardTypeToColor = {
  ...typeToColor,
  [toastType.SUCCESS]: '#38C793',
};
export const DashboardNotificationIconWrapper = styled.div`
  width: 20px;
  min-width: 20px;
  color: ${({ type }) => type && DashboardTypeToColor[type]};
`;
