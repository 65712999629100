import api from 'api';
import { deserializeFiles } from 'deserializers';
import { getReportFiles } from './reportDetails';
import { toastContext, toastType } from 'helpers/toastConstants';
import { setFileLoading } from './me';
import { setToastMessage } from './toastMessages';

// Actions
export const STORE_ROOM_DATA = 'chatrooms.STORE_ROOM_DATA';
export const STORE_ROOM_FINAL_DATA = 'chatrooms.STORE_ROOM_FINAL_DATA';
export const STORE_ROOM_CHAT_STATUS = 'chatrooms.STORE_ROOM_CHAT_STATUS';
export const STORE_INTERNAL_ROOM_DATA = 'chatrooms.STORE_INTERNAL_ROOM_DATA';
export const STORE_CO_CHAT_LIST = 'chatrooms.STORE_CO_CHAT_LIST';
export const STORE_INTERNAL_CHAT_FILES = 'chatrooms.STORE_INTERNAL_CHAT_FILES';
export const SET_FILE_PROCESSING = 'chatrooms.SET_FILE_PROCESSING';
export const SET_STREAMING_CHAT_MESSAGE = 'chatrooms.SET_STREAMING_CHAT_MESSAGE';
export const CLEAR_STREAMING_CHAT_MESSAGE = 'chatrooms.CLEAR_STREAMING_CHAT_MESSAGE';
export const SET_CHAT_MESSAGES_EDITED = 'chatrooms.SET_CHAT_MESSAGES_EDITED';
export const ANONYMIZE_CHAT_MESSAGE = 'chatrooms.ANONYMIZE_CHAT_MESSAGE';
export const ANONYMIZE_INTERNAL_CHAT_MESSAGE =
  'chatrooms.ANONYMIZE_INTERNAL_CHAT_MESSAGE';
export const WS_CONNECT = 'chatrooms.WS_CONNECT';
export const WS_DISCONNECT = 'chatrooms.WS_DISCONNECT';

export const SET_AI_TYPING = 'chatrooms.SET_AI_TYPING';

// Reducer
const initialState = {
  rooms: {
    officerChat: [],
  },
  status: {},
  internalRooms: {},
  internalChatFiles: [],
  chatTouched: false,
  isFileProcessing: false,
  coChatList: [],
  aiTyping: false,
  streamingMessage: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_ROOM_FINAL_DATA:
      return {
        ...state,
        streamingMessage: initialState.streamingMessage,
        rooms: {
          ...state.rooms,
          [action.payload.room]: [...action.payload.data],
        },
      };
    case STORE_ROOM_DATA:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          [action.payload.room]: [...action.payload.data],
        },
      };
    case SET_STREAMING_CHAT_MESSAGE:
      return {
        ...state,
        streamingMessage: state.streamingMessage + action.payload.message,
      };
    case CLEAR_STREAMING_CHAT_MESSAGE:
      return {
        ...state,
        aiTyping: false,
        streamingMessage: initialState.streamingMessage,
      };
    case STORE_ROOM_CHAT_STATUS:
      return {
        ...state,
        status: action.payload.data,
      };
    case STORE_INTERNAL_ROOM_DATA:
      return {
        ...state,
        internalRooms: {
          ...state.internalRooms,
          [action.payload.room]: [...action.payload.data],
        },
      };
    case STORE_CO_CHAT_LIST:
      return {
        ...state,
        coChatList: [...action.payload],
      };
    case STORE_INTERNAL_CHAT_FILES:
      return {
        ...state,
        internalChatFiles: action.payload,
      };
    case SET_FILE_PROCESSING:
      return {
        ...state,
        isFileProcessing: action.payload,
      };
    case SET_CHAT_MESSAGES_EDITED:
      return {
        ...state,
        chatTouched: action.payload,
      };
    case SET_AI_TYPING:
      return {
        ...state,
        aiTyping: action.payload,
      };
    case ANONYMIZE_CHAT_MESSAGE: {
      const { roomId, id, value } = action.payload;
      return {
        ...state,
        rooms: {
          ...state.rooms,
          [roomId]: state.rooms[roomId].map((rec) =>
            rec.id === id ? { ...rec, text: value, touched: true } : rec
          ),
        },
      };
    }
    case ANONYMIZE_INTERNAL_CHAT_MESSAGE: {
      const { roomId, id, value } = action.payload;
      return {
        ...state,
        internalRooms: {
          ...state.internalRooms,
          [roomId]: state.internalRooms[roomId].map((rec) =>
            rec.id === id ? { ...rec, text: value, touched: true } : rec
          ),
        },
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function storeRoomData(room, data) {
  return { type: STORE_ROOM_DATA, payload: { room, data } };
}

export function storeRoomFinalData(room, data) {
  return { type: STORE_ROOM_FINAL_DATA, payload: { room, data } };
}

export function storeRoomChatStatus(data) {
  return { type: STORE_ROOM_CHAT_STATUS, payload: { data } };
}

export function setStreamingChatMessage(message) {
  return { type: SET_STREAMING_CHAT_MESSAGE, payload: { message } };
}

export function clearStreamingChatMessage() {
  return { type: CLEAR_STREAMING_CHAT_MESSAGE };
}

export function storeInternalRoomData(room, data) {
  return { type: STORE_INTERNAL_ROOM_DATA, payload: { room, data } };
}

export function storeCoChatList(data) {
  return { type: STORE_CO_CHAT_LIST, payload: data };
}

export function storeInternalChatFiles(data) {
  return { type: STORE_INTERNAL_CHAT_FILES, payload: data };
}

export function setChatTouched(bool) {
  return { type: SET_CHAT_MESSAGES_EDITED, payload: bool };
}

export function setFileProcessing(bool) {
  return { type: SET_FILE_PROCESSING, payload: bool };
}

export function setAiTyping(bool) {
  return { type: SET_AI_TYPING, payload: bool };
}

export const updateChatroomMessage = ({ roomId, id, value }) => ({
  type: ANONYMIZE_CHAT_MESSAGE,
  payload: { roomId, id, value },
});

export const updateInternalChatroomMessage = ({ roomId, id, value }) => ({
  type: ANONYMIZE_INTERNAL_CHAT_MESSAGE,
  payload: { roomId, id, value },
});

export const wsConnect = () => ({
  type: WS_CONNECT,
});

export const wsDisconnect = () => ({
  type: WS_DISCONNECT,
});

// Side Effects
export function getOfficerChatMessages() {
  return (dispatch) => {
    return api.me
      .getChatMessages()
      .then((res) => {
        dispatch(storeRoomData('officerChat', res.data));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function getOfficerChatFinalMessages() {
  return (dispatch) => {
    return api.me
      .getChatMessages()
      .then((res) => {
        dispatch(storeRoomFinalData('officerChat', res.data));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function getChatStatus() {
  return (dispatch) => {
    return api.me
      .getChatStatus()
      .then((res) => {
        dispatch(storeRoomChatStatus(res.data));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function getChatStatusWithMessages() {
  return (dispatch) => {
    return api.me
      .getChatStatus()
      .then((res) => {
        dispatch(storeRoomChatStatus(res.data));
        dispatch(getOfficerChatMessages());
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function postMessageToOfficer(text) {
  return (dispatch) => {
    return api.me
      .postChatMessage(text)
      .then(() => {
        dispatch(getOfficerChatMessages());
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function postFileToOfficer(file) {
  return (dispatch) => {
    dispatch(setFileProcessing(true));

    return api.me
      .postChatFile(file)
      .then(() => {
        dispatch(getOfficerChatMessages());
      })
      .catch(() => {
        // @todo: Add Error handling
      })
      .finally(() => {
        dispatch(setFileProcessing(false));
      });
  };
}

export function getMisconductRoomData(room) {
  return (dispatch) => {
    return api.misconduct
      .getMisconductChatById(room)
      .then((res) => {
        dispatch(storeRoomData(room, res.data));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function getMisconductInternalRoomData(room) {
  return (dispatch) => {
    return api.misconduct
      .getMisconductInternalChatById(room)
      .then((res) => {
        dispatch(storeInternalRoomData(room, res.data));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function getMisconductRoomDataByToken(token) {
  return (dispatch) => {
    return api.misconduct
      .fetchMyReportMessages(token)
      .then((res) => {
        dispatch(storeRoomData(token, res.data));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function getCoChatList() {
  return (dispatch) => {
    return api.chats
      .getChatList()
      .then((res) => {
        dispatch(storeCoChatList(res.data));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function getInternalChatFiles(id, folders) {
  return (dispatch) => {
    return api.misconduct
      .getReportFiles(id, folders)
      .then((res) => {
        const deserializedFiles = deserializeFiles(res.data);
        dispatch(storeInternalChatFiles(deserializedFiles));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function getCoChatData(id) {
  return (dispatch) => {
    return api.chats
      .getChatMessages(id)
      .then((res) => {
        dispatch(storeRoomData(id, res.data));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function getCoChatStatus(id) {
  return (dispatch) => {
    return api.chats
      .getChatStatus(id)
      .then((res) => {
        // eslint-disable-next-line no-console
        dispatch(storeRoomChatStatus(res.data));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function createMessageForToken({ token, text }) {
  return (dispatch) => {
    return api.misconduct
      .postMessageToMyReport({ token, text })
      .then(() => {
        dispatch(getMisconductRoomDataByToken(token));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function createFileMessageForToken({ token, file }) {
  return (dispatch) => {
    dispatch(setFileProcessing(true));

    return api.misconduct
      .createFileMessageAsAnonymous({ token, file })
      .then(() => {
        dispatch(getMisconductRoomDataByToken(token));
      })
      .catch(() => {
        // @todo: Add Error handling
      })
      .finally(() => {
        dispatch(setFileProcessing(false));
      });
  };
}

export function createMessageForRoomId({ id, text }) {
  return (dispatch) => {
    return api.misconduct
      .createMessageForRoomId({ id, text })
      .then(() => {
        dispatch(getMisconductRoomData(id));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function createMessageForInternalRoomId({ id, text }) {
  return (dispatch) => {
    return api.misconduct
      .createMessageForInternalRoomId({ id, text })
      .then(() => {
        dispatch(getMisconductInternalRoomData(id));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function createFileMessageForRoomId({ id, file }) {
  return (dispatch) => {
    dispatch(setFileProcessing(true));

    return api.misconduct
      .createFileMessageForRoomId({ id, file })
      .then(() => {
        dispatch(getMisconductRoomData(id));
        dispatch(getReportFiles(id, ['report_attachments', 'public_chat_attachments']));
      })
      .catch(() => {
        dispatch(
          setToastMessage({
            content: {
              translationKey: 'uploadFileError',
              param: file.name,
            },
            context: toastContext.REPORT_MANAGEMENT,
            type: toastType.ALERT,
          })
        );
      })
      .finally(() => {
        dispatch(setFileLoading(false));
        dispatch(setFileProcessing(false));
      });
  };
}

export function createFileMessageForInternalRoomId({ id, file }) {
  return (dispatch) => {
    dispatch(setFileProcessing(true));

    return api.misconduct
      .createFileMessageForInternalRoomId({ id, file })
      .then(() => {
        dispatch(getMisconductInternalRoomData(id));
        dispatch(getInternalChatFiles(id, ['internal_chat_attachments']));
      })
      .catch(() => {
        dispatch(
          setToastMessage({
            content: {
              translationKey: 'uploadFileError',
              param: file.name,
            },
            context: toastContext.REPORT_MANAGEMENT,
            type: toastType.ALERT,
          })
        );
      })
      .finally(() => {
        dispatch(setFileLoading(false));
        dispatch(setFileProcessing(false));
      });
  };
}

export function sendCoMessage({ id, text }) {
  return (dispatch) => {
    return api.chats
      .sendMessage({ id, text })
      .then(() => {
        dispatch(getCoChatData(id));
        dispatch(getCoChatList());
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function sendCoFileMessage({ id, file }) {
  return (dispatch) => {
    dispatch(setFileProcessing(true));

    return api.chats
      .sendFileMessage({ id, file })
      .then(() => {
        dispatch(getCoChatData(id));
        dispatch(getCoChatList());
      })
      .catch(() => {
        // @todo: Add Error handling
      })
      .finally(() => {
        dispatch(setFileProcessing(false));
      });
  };
}

export function finishThreadByCO(id) {
  return (dispatch) => {
    return api.chats
      .finishChatThread(id)
      .then(() => {
        dispatch(getCoChatData(id));
        dispatch(getCoChatStatus(id));
        dispatch(getCoChatList());
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function finishThread() {
  return (dispatch) => {
    return api.me
      .finishChatThread()
      .then(() => {
        dispatch(getChatStatusWithMessages());
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function escalateToCO() {
  return (dispatch) => {
    return api.me
      .escalateToCO()
      .then(() => {
        dispatch(getChatStatusWithMessages());
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}
