import styled, { css, keyframes } from 'styled-components';
import { rem } from 'polished';

import { globalSize, colorName, transitions } from 'styles';

const BUTTON_SIZE = 64;

export const pulse = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.35);
    opacity: 0;
  }
`;

export const zoom = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
`;

export const FloatingButton = styled.button`
  position: fixed;
  right: ${rem(globalSize.defaultPadding * 2)};
  bottom: ${rem(globalSize.defaultPadding * 2)};
  width: ${rem(BUTTON_SIZE)};
  height: ${rem(BUTTON_SIZE)};
  padding: ${rem(globalSize.defaultPadding)};
  color: ${colorName.black};
  background: ${colorName.primary};
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  appearance: none;

  ${({ isNew }) =>
    isNew &&
    css`
      & > svg {
        animation: ${zoom} 0.9s ${transitions.spring} infinite;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        border: 3px solid ${colorName.primary};
        border-radius: 100%;
        z-index: -1;
        animation: ${pulse} 1.2s ${transitions.spring} infinite;
      }
    `};
`;

export const ModalWrapper = styled.div`
  padding: ${rem(globalSize.defaultPadding)};
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  gap: ${rem(8)};
  justify-content: end;
  padding: ${rem(globalSize.defaultPadding)};
  padding-top: 0;
`;
