import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import api from 'api';
import {
  postMessageToOfficer,
  postFileToOfficer,
  wsConnect,
  wsDisconnect,
  getChatStatusWithMessages,
  finishThread,
  escalateToCO,
  clearStreamingChatMessage,
} from 'stores/chatrooms';

import Modal from 'components/Modal';
import Typography from 'components/Typography';
import { ChatMessageInput, ChatMessages } from 'components/ChatAI';

import { ActionButtonsWrapper, ModalWrapper } from './style';
import { messages } from './messages';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl.macro';

const propTypes = {
  onClose: PropTypes.func.isRequired,
};

const ChatModal = ({ onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    streamingMessage,
    isFileProcessing,
    rooms: { officerChat },
    status,
    aiTyping,
  } = useSelector((state) => state.chatrooms);
  const {
    properties: { aiChat },
  } = useSelector((state) => state.tenantProperties);
  const [isAiModerator, setIsAiModerator] = useState(true);

  const topicIsOpen = useMemo(
    () => !status || !status.ai_chat_topic || !status.ai_chat_topic.is_open,
    [status]
  );
  const runId = useMemo(() => status?.ai_chat_topic?.run_id, [status]);

  useEffect(() => {
    if (topicIsOpen) {
      return;
    }
    dispatch(wsConnect());

    return () => {
      dispatch(wsDisconnect());
    };
  }, [dispatch, topicIsOpen, runId]);

  useEffect(() => {
    setIsAiModerator(
      aiChat && status && status.ai_chat_topic && status.ai_chat_topic.suspended === false
    );
  }, [aiChat, status]);

  const sendMessage = useCallback(
    (text) => {
      dispatch(postMessageToOfficer(text));
    },
    [dispatch]
  );

  const sendFile = useCallback(
    (file) => {
      dispatch(postFileToOfficer(file));
    },
    [dispatch]
  );

  const refreshMessages = useCallback(
    () => dispatch(getChatStatusWithMessages()),
    [dispatch]
  );

  const handleOnClose = () => {
    onClose();
    dispatch(clearStreamingChatMessage());
  };

  return (
    <Modal isOpen autoHeight isNarrowContent onRequestClose={handleOnClose}>
      <ModalWrapper>
        <Typography size="large" bottomMargin={32}>
          {intl.formatMessage(messages.header)}
        </Typography>

        <ChatMessages
          messages={officerChat}
          streamingMessage={streamingMessage}
          fileUrlResolver={api.me.resolveFileUrl}
          refreshMessages={refreshMessages}
          sendHandler={sendMessage}
        />

        {aiChat && (
          <ActionButtonsWrapper>
            <Button
              disabled={status?.ai_chat_topic?.suspended}
              secondary
              isSmall
              onClick={() => dispatch(escalateToCO())}
            >
              <FormattedMessage {...messages.escalateToCO} />
            </Button>
            <Button
              primary
              isSmall
              onClick={() => {
                dispatch(wsDisconnect());
                dispatch(finishThread());
              }}
            >
              <FormattedMessage {...messages.finishThread} />
            </Button>
          </ActionButtonsWrapper>
        )}

        <ChatMessageInput
          sendHandler={sendMessage}
          uploadHandler={sendFile}
          aiTyping={aiTyping}
          areUploadsAllowed={!isAiModerator}
          uploadingInProgress={isFileProcessing}
        />
      </ModalWrapper>
    </Modal>
  );
};

ChatModal.propTypes = propTypes;

export default ChatModal;
