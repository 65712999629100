import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { coStatisticPropTypes } from 'models';
import StatisticVisualization from 'components/StatsiticVisualization';

import {
  Statistics,
  Wrapper,
  Content,
  Statistic,
  LeftContentWrapper,
  RightContentWrapper,
  TopRow,
  BarChartWrapper,
  FilterWrapper,
} from './styles';
import { messages } from '../messages';
import HeaderWithActions from 'components/HeaderWithActions';
import TilesSection from './TilesSection';
import ReactTooltip from 'components/Tooltip';
import DonutChartSection from './DonutChartSection';
import BarChartSection from './BarChartSection';
import SimpleSelect from 'components/AutocompleteSelect/SimpleSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getBarStatistics, getDonutStatistics } from 'stores/coDashboard';
import MatchMedia from 'components/MatchMedia';
import { deviceSizes } from 'styles';
import moment from 'moment';
import { MISCONDUCTS_MODULE } from '../../../../../stores/auth';
import ProtectedSection from 'components/RolesManagement/ProtectedSetion';

const propTypes = {
  statistics: PropTypes.arrayOf(coStatisticPropTypes).isRequired,
};

const date = new Date();
export const timeFilterOptions = [
  {
    value: 'time-1',
    label: <FormattedMessage {...messages.filterOneMonth} />,
    dateCreated: moment(date).subtract(30, 'days').toDate(),
    dateClosed: moment(date).toDate(),
  },
  {
    value: 'time-2',
    label: <FormattedMessage {...messages.filterThreeMonths} />,
    dateCreated: moment(date).subtract(90, 'days').toDate(),
    dateClosed: moment(date).toDate(),
  },
  {
    value: 'time-3',
    label: <FormattedMessage {...messages.filterOneYear} />,
    dateCreated: moment(date).subtract(365, 'days').toDate(),
    dateClosed: moment(date).toDate(),
  },
  {
    value: 'time-4',
    label: <FormattedMessage {...messages.filterCurrentYear} />,
    dateCreated: moment().startOf('year').toDate(),
    dateClosed: moment(date).toDate(),
  },
  {
    value: 'time-5',
    label: <FormattedMessage {...messages.filterAll} />,
  },
];

export const StatisticsSection = ({ statistics }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isLoading, timeFilter, donutFilters, barFilters, barSort } = useSelector(
    (state) => state.coDashboard
  );

  const misconductsStatistics = statistics.find(
    (stats) => stats.type === MISCONDUCTS_MODULE
  );

  return (
    !!statistics.length &&
    !isLoading && (
      <Wrapper>
        <Content>
          <HeaderWithActions
            titleComponentName="h2"
            label={<FormattedMessage {...messages.statisticsTitle} />}
            testingId="dashboard-header"
          >
            <FilterWrapper>
              <SimpleSelect
                id="statistics-time-range-select"
                options={timeFilterOptions}
                value={
                  Object.keys(timeFilter).length ? [timeFilter] : [timeFilterOptions[0]]
                }
                onChange={(evt) => {
                  dispatch(
                    getDonutStatistics({
                      timeFilter: evt.target.value,
                      donutFilters,
                    })
                  );
                  dispatch(
                    getBarStatistics({
                      timeFilter: evt.target.value,
                      barFilters,
                      barSort,
                    })
                  );
                }}
                marginBottom={0}
              />
            </FilterWrapper>
            <MatchMedia minWidth={deviceSizes.tablet}>
              <ReactTooltip
                id="statistics-tooltip"
                content={intl.formatMessage(messages.statisticsTooltip)}
              />
            </MatchMedia>
          </HeaderWithActions>
        </Content>

        <Statistics>
          <TopRow>
            <LeftContentWrapper>
              {misconductsStatistics ? (
                <TilesSection
                  unassignedNumber={misconductsStatistics.remaining}
                  inProgressNumber={misconductsStatistics.progressed}
                />
              ) : null}

              <Statistic key={statistics[0].type}>
                <StatisticVisualization statistic={statistics[0]} />
              </Statistic>
            </LeftContentWrapper>
            <ProtectedSection modules={[MISCONDUCTS_MODULE]} as={'officer'}>
              <RightContentWrapper>
                <DonutChartSection />
              </RightContentWrapper>
            </ProtectedSection>
          </TopRow>
          <ProtectedSection modules={[MISCONDUCTS_MODULE]} as={'officer'}>
            <BarChartWrapper>
              <BarChartSection />
            </BarChartWrapper>
          </ProtectedSection>
        </Statistics>
      </Wrapper>
    )
  );
};

StatisticsSection.propTypes = propTypes;

export default StatisticsSection;
