import styled, { keyframes } from 'styled-components';
import { rem, rgba } from 'polished';

import { breakpoints, colorName, colors, font, variables } from 'styles';
import { inputStyles } from 'components/Input/style';
import { StyledTextarea } from 'components/Textarea/style';

const ICON_SIZE = 18;
const ICON_SPACING = 8;
const MESSAGE_WIDTH = 450;
const CHAT_HEIGHT = 450;

export const ChatWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${rem(CHAT_HEIGHT)};
  margin: 0 0 ${rem(16)} 0;
  padding: ${rem(16)} 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${rem(variables.borderRadius)};

  &:after {
    position: absolute;
    top: ${rem(8)};
    left: 0;
    width: 100%;
    height: ${rem(24)};
    background: ${({ backgroundColor }) =>
      `linear-gradient(to bottom, ${rgba(backgroundColor, 1)} 30%, ${rgba(
        backgroundColor,
        0
      )} 100%)`};
    content: '';
    pointer-events: none;
  }

  @media ${breakpoints.tablet} {
    margin: 0 0 ${rem(32)} 0;
    padding: ${rem(12)} 0 0 0;
  }
`;

export const WelcomeMessageWrapper = styled.div`
  margin-bottom: ${rem(22)};
  padding: ${rem(10)} ${rem(12)};
  font-size: ${rem(font.size.small)};
  line-height: ${rem(22)};
  background: ${colors.lightGray};
  border-radius: ${rem(8)};
`;

export const NoMessagesWrapper = styled.div`
  color: ${colors.mediumGray};
  font-size: ${rem(font.size.small)};
  text-align: center;
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  padding: ${rem(24)} ${rem(20)} 0 0;
  overflow-y: scroll;
`;

export const MessageAnswersWrapper = styled.div`
  display: flex;
  gap: ${rem(4)};
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isOutcoming }) => (isOutcoming ? 'row-reverse' : 'row')};
  margin: 0 0 ${rem(16)} ${rem(12)};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ isOutcoming }) => (isOutcoming ? 'flex-end' : 'flex-start')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  margin: ${({ isOutcoming }) => (isOutcoming ? `0 ${rem(8)} 0 0` : `0 0 0 ${rem(8)}`)};
`;

export const Text = styled.div`
  width: auto;
  margin: 0 0 ${rem(8)} 0;
  padding: ${rem(12)} ${rem(24)};
  color: ${colorName.black};
  font-size: ${rem(font.size.small)};
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-world;
  background-color: ${colors.lighterGray};
  border-radius: ${rem(variables.borderRadius)}
    ${({ isDark, isOutcoming }) =>
      isDark &&
      !isOutcoming &&
      `
    background: ${colors.yellow};
  `};

  @media ${breakpoints.tablet} {
    max-width: ${rem(MESSAGE_WIDTH)};
    padding: ${rem(12)} ${rem(16)};
  }
`;

export const File = styled(Text)`
  display: flex;
  align-items: center;
  padding: ${rem(12)} ${rem(24)};
  color: ${colorName.secondary};
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.tiny)};
  text-decoration: none;
  word-break: break-all;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  & > svg {
    width: ${rem(ICON_SIZE / 2)};
    height: ${rem(ICON_SIZE / 2)};
    margin: 0 ${rem(4)} 0 0;
  }
`;

export const Time = styled.span`
  color: ${colors.darkGray};
  font-size: ${rem(font.size.tiny)};
`;

export const ChatMessageAuthor = styled.span`
  color: ${colors.darkGray};
  font-size: ${rem(font.size.small)};
`;

export const Input = styled.textarea`
  ${inputStyles};
  padding: ${rem(12)} ${rem(2 * ICON_SIZE + 4 * ICON_SPACING)} ${rem(12)} ${rem(24)};
`;

export const InputWrapper = styled.form`
  position: relative;
`;

export const FileLoader = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${rem(4)};
`;

export const IconsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  color: ${({ isDark }) => (isDark ? colorName.white : colorName.secondary)};
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${rem(ICON_SPACING)};
  color: inherit;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;

  &:last-child {
    margin: 0 ${rem(ICON_SPACING)} 0 0;
  }

  & > svg {
    width: ${rem(ICON_SIZE)};
    height: ${rem(ICON_SIZE)};
  }

  &:disabled {
    color: ${colors.gray};
  }
`;

export const ChatAnonymizeTextArea = styled(StyledTextarea)`
  height: ${rem(56)};
  margin: 0 0 ${rem(8)} 0;
  text-align: ${({ rtl }) => (rtl ? 'right' : 'left')};
`;

export const FinishThreadWrapper = styled.div`
  display: flex;
  gap: ${rem(8)};
  align-items: center;
  justify-content: center;
  margin: ${rem(24)} 0;
  padding: 0 ${rem(12)};
  color: ${colors.darkGray};
  font-size: ${rem(font.size.small)};

  & span {
    font-weight: ${font.weight.bold};
  }
`;

export const ThreadWillEndWrapper = styled.div`
  display: flex;
  gap: ${rem(8)};
  align-items: center;
  justify-content: center;
  padding: 0 ${rem(12)};
  color: ${colors.darkGray};
  font-size: ${rem(font.size.small)};

  & span {
    font-weight: ${font.weight.bold};
  }
`;

export const LockIconWrapper = styled.div`
  width: ${rem(32)};
  height: ${rem(32)};
  padding: ${rem(6)};
  color: black;
  background: ${colors.athensGray};
  border-radius: ${rem(12)};
`;

export const JoinThreadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  padding: 16px var(--border-radius-rounded-none, 0px);
  color: ${colors.darkGray};
  font-size: ${rem(font.size.small)};
  background: ${colors.lightGray};
  border-radius: 8px;
`;

export const ThreadDividerWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: ${rem(48)} 0;
  border-bottom: 1px solid ${colors.gray};
`;

export const ThreadDividerBoxWrapper = styled.div`
  position: absolute;
  left: 50%;
  display: block;
  overflow: hidden;
  color: ${colors.mediumGray};
  background: white;
  transform: translate(-50%, -50%);
`;

export const ThreadDividerBox = styled.span`
  position: relative;
  display: block;
  margin-right: 0.8em;
  margin-left: 0.8em;
  padding: 2px 10px;
  font-size: ${rem(font.size.tiny)};
  white-space: nowrap;
  text-decoration: center;
  border-color: ${colors.gray};
  border-style: solid;
  border-width: 1px 0;
  transition: border-color 500ms ease, color 250ms ease;

  &:before {
    position: absolute;
    top: -50%;
    right: 0;
    bottom: 50%;
    left: -0.8em;
    display: inline-block;
    border-color: ${colors.gray} !important;
    border-bottom-width: 1px !important;
    border-left-width: 1px !important;
    transform: rotate(45deg);
    transform-origin: 0% 100%;
    content: '';
  }

  &:after {
    position: absolute;
    top: -50%;
    right: -0.8em;
    bottom: 50%;
    left: 0;
    display: inline-block;
    border-color: ${colors.gray} !important;
    border-right-width: 1px !important;
    border-bottom-width: 1px !important;
    transform: rotate(-45deg);
    transform-origin: 100% 100%;
    content: '';
  }

  &:before,
  &:after {
    border-color: white;
    border-style: solid;
    border-width: 0;
  }
`;

const BounceAnimation = keyframes`
  0% { transform: translate3d(0, 0,0); }
  50% {transform: translate3d(0, 5px,0);}
  100% { transform: translate3d(0, 0,0); }
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  margin: 0 2px;
  background-color: ${colors.darkGray};
  border-radius: 50%;
  animation: ${BounceAnimation} 0.7s ease-in infinite;
  animation-delay: ${(props) => props.delay};
`;

export const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 15px;
  margin-right: 10px;
  margin-left: 10px;
`;
