import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { filterRoles, getOfficerRoles, hasRoleRights } from '../../stores/auth';

const propTypes = {
  modules: PropTypes.array,
  roles: PropTypes.array,
  children: PropTypes.any,
  as: PropTypes.oneOf(['officer', 'employee']),
};

function ProtectedSection({ children, modules, roles, as }) {
  let { roles: userRoles } = useSelector((state) => state.auth);

  if (as === 'officer') {
    userRoles = getOfficerRoles(userRoles);
  }

  const filtered = roles ? filterRoles(userRoles, roles) : userRoles;

  if (!hasRoleRights(filtered, modules)) return null;

  return children;
}

ProtectedSection.propTypes = propTypes;

export default ProtectedSection;
