import api from 'api';

export const STATUS_INITIAL = 'initial';
export const STATUS_LOADING = 'loading';
export const STATUS_READY = 'ready';

// Actions
export const SET_TENANT_PROPERTIES = 'tenantProperties.SET_TENANT_PROPERTIES';
export const SET_LOADING = 'tenantProperties.SET_LOADING';
export const SET_ALLOW_MISCONDUCT_UPLOADS =
  'tenantProperties.SET_ALLOW_MISCONDUCT_UPLOADS';
export const SET_SESSION_TIMEOUT = 'tenantProperties.SET_SESSION_TIMEOUT';
export const SET_RECAPTCHA_ACTIVE = 'tenantProperties.SET_RECAPTCHA_ACTIVE';

// Reducer
const initialState = {
  properties: {
    poweredBy: '',
    logoUrl: '',
    loginImageUrl: '',
    smallLogoUrl: '',
    defaultLanguage: '',
    misconductLanguages: [],
    contactEmail: '',
    misconductInstructionsFile: null,
    misconductInstructionsLabel: '',
    misconductTipFile: null,
    misconductTipLabel: '',
    privacyPolicyFile: null,
    privacyPolicyLabel: '',
    termsOfUseFile: null,
    termsOfUseLabel: '',
    impressumFile: null,
    impressumLabel: '',
    customConfigFile: null,
    customConfig: null,
    twoFactorAuthActive: false,
    logoutTimeEmployee: 60,
    logoutTimeOfficer: 60,
    aiChat: false,
    modules: null,
  },
  recaptchaActive: true,
  allowMisconductFileUploads: false,
  status: STATUS_INITIAL,
  sessionTimeout: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TENANT_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
        status: STATUS_READY,
      };
    case SET_ALLOW_MISCONDUCT_UPLOADS:
      return {
        ...state,
        allowMisconductFileUploads: action.payload,
      };
    case SET_SESSION_TIMEOUT:
      return {
        ...state,
        sessionTimeout: action.payload,
      };
    case SET_RECAPTCHA_ACTIVE:
      return {
        ...state,
        recaptchaActive: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    default:
      return state;
  }
}

// Action Creators
export function setTenantProperties(properties) {
  return { type: SET_TENANT_PROPERTIES, payload: properties };
}

export function toggleMisconductFileUploads(bool) {
  return { type: SET_ALLOW_MISCONDUCT_UPLOADS, payload: bool };
}

export function setSessionTimeout(data) {
  return { type: SET_SESSION_TIMEOUT, payload: data };
}

export function setRecaptchaActive(data) {
  return { type: SET_RECAPTCHA_ACTIVE, payload: data };
}

export function setLoading() {
  return { type: SET_LOADING };
}

// Side Effects
export function fetchTenantProperties(language = '', apiClient = api) {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const response = await apiClient.config.getConfig(language);
      const misconductInstruction = response.data.public_files.filter(
        (item) => item.key === 'misconduct_instruction'
      );
      const impressum = response.data.public_files.filter(
        (item) => item.key === 'impressum'
      );
      const customConfigFile = response.data.public_files.filter(
        (item) => item.key === 'custom_config'
      );
      const misconductTip = response.data.public_files.filter(
        (item) => item.key === 'misconduct_tip_file'
      );
      const privacyPolicy = response.data.public_files.filter(
        (item) => item.key === 'privacy_policy'
      );
      const termsOfUse = response.data.public_files.filter(
        (item) => item.key === 'terms_of_use'
      );
      const customConfig = await apiClient.config.getCustomConfig();

      dispatch(toggleMisconductFileUploads(response.data.allow_misconduct_uploads));
      dispatch(setRecaptchaActive(response.data.recaptcha_active));
      dispatch(
        setTenantProperties({
          loginImageUrl: response.data.splash_screen_url,
          logoUrl: response.data.logo_url,
          smallLogoUrl: response.data.small_logo_url,
          defaultLanguage: response.data.default_language,
          poweredBy: response.data.powered_by_ey,
          misconductLanguages: response.data.misconduct_enabled_languages,
          contactEmail: response.data.contact,
          customPath: response.data.custom_path,
          logoutTimeEmployee: response.data.logout_time_employee,
          logoutTimeOfficer: response.data.logout_time_officer,
          misconductInstructionsFile: misconductInstruction[0]?.url || '',
          misconductInstructionsLabel: misconductInstruction[0]?.label || '',
          misconductTipFile: misconductTip[0]?.url || '',
          misconductTipLabel: misconductTip[0]?.label || '',
          privacyPolicyFile: privacyPolicy[0]?.url || '',
          privacyPolicyLabel: privacyPolicy[0]?.label || '',
          termsOfUseFile: termsOfUse[0]?.url || '',
          termsOfUseLabel: termsOfUse[0]?.label || '',
          impressumFile: impressum[0]?.url || '',
          impressumLabel: impressum[0]?.label || '',
          customConfigFile: customConfigFile[0]?.url || '',
          customConfig,
          twoFactorLogin: response.data.two_factor_auth_active,
          aiChat: response.data.use_ai_chatbot,
          modules: response.data.modules,
        })
      );
    } catch (error) {
      console.error('An error occured while fetching tenant properties: ', error); // eslint-disable-line no-console
      dispatch(setTenantProperties({}));
    }
  };
}
