module.exports = {
  LANG_EN: 'en',
  LANG_ES: 'es',
  LANG_PL: 'pl',
  LANG_DE: 'de',
  LANG_EL: 'el',
  LANG_BG: 'bg',
  LANG_CS: 'cs',
  LANG_HR: 'hr',
  LANG_HU: 'hu',
  LANG_RO: 'ro',
  LANG_RU: 'ru',
  LANG_SL: 'sl',
  LANG_SR: 'sr',
  LANG_TR: 'tr',
  LANG_IT: 'it',
  LANG_UK: 'uk',
  LANG_PT: 'pt',
  LANG_FR: 'fr',
  LANG_SV: 'sv',
  LANG_SK: 'sk',
  LANG_FI: 'fi',
  LANG_NL: 'nl',
  LANG_AR: 'ar',
  LANG_ET: 'et',
  SUPPORTED_LOCALES: [
    'en',
    'es',
    'pl',
    'de',
    'fi',
    'el',
    'bg',
    'cs',
    'hr',
    'hu',
    'ro',
    'ru',
    'sl',
    'sr',
    'tr',
    'it',
    'uk',
    'pt',
    'fr',
    'sv',
    'sk',
    'nl',
    'ar',
    'et',
  ],
  // @todo: Refactor and move to specific objects for simple imports
  DOC_NEW: 'new',
  DOC_PENDING: 'pending',
  DOC_OVERDUE: 'overdue',
  DOC_ARCHIVED: 'archived',
  DOC_AVAILABLE: 'available',
  DOC_CONFIRMED: 'confirmed',
  PAGINATE_DEFAULT_PAGE: 1,
  PAGINATE_DEFAULT_PER_PAGE: 10,
  EMPLOYEE_SCOPE: 'employee',
  EXTENDED_EMPLOYEE_SCOPE: 'extended_employee',
  EXTENDED_VIEW_SCOPE: 'extended_view',
  OFFICER_SCOPE: 'officer',
  FILESIZE_3MB: 3145728,
  FILESIZE_5MB: 52415670,
  FILESIZE_10MB: 10485760,
  FILESIZE_20MB: 20971520,
  FILESIZE_50MB: 52428800,
  CHAT_FILETYPES_ALLOWED: [
    // ALLOWED ALL TYPES BECAUSE OF ISSUES WITH MIMETYPE TO .msg FILES
    // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    // 'application/msword',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/pdf',
    // 'application/vnd.ms-excel',
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // 'image/jpeg',
    // 'image/png',
    // 'application/pdf',
    // 'text/csv',
    // 'application/vnd.ms-outlook',
    // 'application/octet-stream',
  ],
  REPORT_FILETYPES_ALLOWED: [
    // ALLOWED ALL TYPES BECAUSE OF ISSUES WITH MIMETYPE TO .msg FILES
    // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    // 'application/msword',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/pdf',
    // 'application/vnd.ms-excel',
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // 'image/jpeg',
    // 'image/png',
    // 'application/pdf',
    // 'application/vnd.ms-outlook',
    // 'application/octet-stream',
  ],
  REPORT_CASE_FILETYPES_ALLOWED: [
    // ALLOWED ALL TYPES BECAUSE OF ISSUES WITH MIMETYPE TO .msg FILES
    // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    // 'application/msword',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/pdf',
    // 'application/vnd.ms-excel',
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // 'image/jpeg',
    // 'image/png',
    // 'application/pdf',
    // 'application/vnd.ms-outlook',
    // 'application/octet-stream',
  ],
  IMPORT_FILETYPES_ALLOWED: [
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  DATE_FORMAT_TRANSFER: 'YYYY-MM-DD',
  SORT_DIRECTION_ASCENDING: 'asc',
  SORT_DIRECTION_DESCENDING: 'desc',
};
