import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { ROUTING } from 'routing';
import { hasRoleRights } from '../../stores/auth';

const propTypes = { component: PropTypes.object, modules: PropTypes.array };

const AuthRoute = ({ component: Component, modules, ...props }) => {
  const { userData, isAuthenticated, roles } = useSelector((state) => state.auth);

  const hasRole = modules ? hasRoleRights(roles, modules) : true;

  return (
    <Route
      {...props}
      render={(props) =>
        isAuthenticated && userData && hasRole ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTING.INDEX} />
        )
      }
    />
  );
};

AuthRoute.propTypes = propTypes;

export default AuthRoute;
