import React from 'react';

const ManageDocumentIcon = (props) => (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6389 4H18V21H1V4H3.36111"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <line x1="5" y1="10.5" x2="15" y2="10.5" stroke="currentColor" />
    <line x1="5" y1="13.5" x2="12" y2="13.5" stroke="currentColor" />
    <line x1="5" y1="16.5" x2="15" y2="16.5" stroke="currentColor" />
    <line x1="3" y1="10.5" x2="4" y2="10.5" stroke="currentColor" />
    <line x1="3" y1="13.5" x2="4" y2="13.5" stroke="currentColor" />
    <line x1="3" y1="16.5" x2="4" y2="16.5" stroke="currentColor" />
    <path
      d="M12 3.27273C12 2.01773 10.8805 1 9.5 1C8.1195 1 7 2.01773 7 3.27273H5V6H14V3.27273H12Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default ManageDocumentIcon;
