import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  groupCreatedSuccess: {
    id: 'ToastMessages.groupCreatedSuccess',
    defaultMessage: 'Group {param} has been created',
    description: 'Group Created Success',
  },
  groupEditedSuccess: {
    id: 'ToastMessages.groupEditedSuccess',
    defaultMessage: 'Group {param} has been edited',
    description: 'Group Edited Success',
  },
  groupDeletedSuccess: {
    id: 'ToastMessages.groupDeletedSuccess',
    defaultMessage: 'Group {param} has been deleted',
    description: 'Group Deleted Success',
  },

  categoryDeletedSuccess: {
    id: 'ToastMessages.categoryDeletedSuccess',
    defaultMessage: 'Category has been deleted',
    description: 'Category Deleted Success',
  },

  categoryUpdateFailed: {
    id: 'ToastMessages.categoryUpdateError',
    defaultMessage: 'Category update failed',
    description: 'Category Update Failed',
  },

  categoryCreateFailed: {
    id: 'ToastMessages.categoryCreateFailed',
    defaultMessage: 'Category creation failed',
    description: 'Category Creation Failed',
  },

  importCompleted: {
    id: 'UserManagement.importCompleted',
    defaultMessage: 'Import has been completed successfully!',
    description: 'Import Completed Toast',
  },
  importPrepared: {
    id: 'UserManagement.importPrepared',
    defaultMessage: 'Import has been prepared and ready to invoke!',
    description: 'Import Prepared Toast',
  },
  importScheduled: {
    id: 'UserManagement.importScheduled',
    defaultMessage: 'Import has been invoked.',
    description: 'Import Scheduled Toast',
  },
  importRunning: {
    id: 'UserManagement.importRunning',
    defaultMessage:
      'Import is running in background. Do not preform any operations on groups or users!',
    description: 'Import Running Toast',
  },
  importFinished: {
    id: 'UserManagement.importFinished',
    defaultMessage: 'Import has been completed successfully!',
    description: 'Import Finished Toast',
  },
  importError: {
    id: 'UserManagement.importError',
    defaultMessage: 'Import failed. There has been errors!',
    description: 'Import Error Toast',
  },
  userAdded: {
    id: 'ToastMessages.userAdded',
    defaultMessage: 'User has been added successfully!',
    description: 'User added Toast',
  },
  importCanceled: {
    id: 'UserManagement.importCanceled',
    defaultMessage: 'Import has been canceled',
    description: 'Import Canceled Toast',
  },
  userEditedSuccess: {
    id: 'UserManagement.userEditedSuccess',
    defaultMessage: 'User {param} has been successfully edited',
    description: 'User Edited Toast',
  },
  userPasswordSent: {
    id: 'UserManagement.userPasswordSent',
    defaultMessage: 'Password reset link for {param} has been sent',
    description: 'User Password sent Toast',
  },
  userPasswordNotSent: {
    id: 'UserManagement.userPasswordNotSent',
    defaultMessage: 'Something went wrong. Please try again.',
    description: 'User Password sent error Toast',
  },
  userDeactivated: {
    id: 'UserManagement.userDeactivated',
    defaultMessage: 'User {param} was deactivated.',
    description: 'User deactivated Toast',
  },
  userNotDeactivated: {
    id: 'UserManagement.userNotDeactivated',
    defaultMessage: 'Something went wrong. Please try again.',
    description: 'User not deactivated error Toast',
  },
  userActivated: {
    id: 'UserManagement.userActivated',
    defaultMessage: 'User {param} was activated.',
    description: 'User activated Toast',
  },
  userNotActivated: {
    id: 'UserManagement.userNotActivated',
    defaultMessage: 'Something went wrong. Please try again.',
    description: 'User not activated error Toast',
  },
  userRulesSaved: {
    id: 'UserManagement.userRulesSaved',
    defaultMessage: 'User rules has been saved',
    description: 'User rules success Toast',
  },
  userRulesNotSaved: {
    id: 'UserManagement.userRulesNotSaved',
    defaultMessage: 'User rules has not been saved, try again later',
    description: 'User rules error Toast',
  },
  groupRulesSaved: {
    id: 'UserManagement.groupRulesSaved',
    defaultMessage: 'Group rules has been saved',
    description: 'Group rules success Toast',
  },
  groupRulesNotSaved: {
    id: 'UserManagement.groupRulesNotSaved',
    defaultMessage: 'Group rules has not been saved, try again later',
    description: 'Group rules error Toast',
  },
  reportStateEditedSuccess: {
    id: 'ToastMessages.reportStateEditedSuccess',
    defaultMessage: 'Report status has been edited',
    description: 'Report Edited Success',
  },
  notificationReadFailure: {
    id: 'ToastMessages.notificationReadFailure',
    defaultMessage: "Couldn't mark notification as read. Please try again later",
    description: 'Report Edited Success',
  },
  documentNotFound: {
    id: 'ToastMessages.documentNotFound',
    defaultMessage: 'Couldn not find the document you are looking for.',
    description: 'Report Edited Success',
  },
  uploadFileError: {
    id: 'ToastMessages.uploadFileError',
    defaultMessage: 'Uploading file {param} failed. Please try again.',
    description: 'Upload File Error',
  },
  summaryPosted: {
    id: 'ToastMessages.summaryPosted',
    defaultMessage:
      'Feedback has been sent via the dialogue tab. The whistleblower also received an email notification (if an email address was provided).',
    description: 'Summary posted success',
  },
  passwordExpired: {
    id: 'ToastMessages.passwordExpired',
    defaultMessage: 'Your password has expired, please set up new password.',
    description: 'Password expiration toast',
  },
  passwordChanged: {
    id: 'ToastMessages.passwordChanged',
    defaultMessage: 'Your password has been changed',
    description: 'Password change notification',
  },
  documentEditedSuccess: {
    id: 'ToastMessages.documentEditedSuccess',
    defaultMessage: 'Document {param} has been edited',
    description: 'Document Edit Success',
  },
  documentEditedFailed: {
    id: 'ToastMessages.documentEditedFailed',
    defaultMessage: 'Editing document {param} failed. Please try again.',
    description: 'Document Edit Error',
  },
  reportFilesUpdated: {
    id: 'ToastMessages.reportFilesUpdated',
    defaultMessage: 'Files repository has been updated',
    description: 'Report files update toast message',
  },
  reportNoteUpdated: {
    id: 'ToastMessages.reportNoteUpdated',
    defaultMessage: '[TD] Note has been updated',
    description: 'Report note update toast message',
  },
  documentArchiveSuccess: {
    id: 'ToastMessages.documentArchiveSuccess',
    defaultMessage: 'Document has been archived successfully',
    description: 'Document archive toast',
  },
  documentRestoreSuccess: {
    id: 'ToastMessages.documentRestoreSuccess',
    defaultMessage: 'Document has been restored successfully',
    description: 'Document restore toast',
  },
  documentDeleteSuccess: {
    id: 'ToastMessages.documentDeleteSuccess',
    defaultMessage: 'Document has been deleted successfully',
    description: 'Document delete toast',
  },
  documentNewVersionSuccess: {
    id: 'ToastMessages.documentNewVersionSuccess',
    defaultMessage: 'New document version has been created',
    description: 'Creating a new version of document success Toast',
  },
  documentNewVersionFailed: {
    id: 'ToastMessages.documentNewVersionFailed',
    defaultMessage: 'Something went wrong. Please try again.',
    description: 'Creating a new version of document error Toast',
  },
  documentReminderSent: {
    id: 'ToastMessages.documentReminderSent',
    defaultMessage:
      'Reminder Sent. User will receive a reminder about the overdue document confirmation.',
    description: 'Sending a document reminder success toast',
  },
  documentReminderError: {
    id: 'ToastMessages.documentReminderError',
    defaultMessage: 'Failed to send document reminder.',
    description: 'Sending a document reminder error toast',
  },
  userIsOwnerOfDocumentError: {
    id: 'ToastMessages.userIsOwnerOfDocumentError',
    defaultMessage:
      'User {param} is an owner of the: {param2} document(s). Please assign documents to another user and then try to deactivate this user again',
    description: 'User is an owner of document error toast',
  },
  userIsOfficerError: {
    id: 'ToastMessages.userIsOfficerError',
    defaultMessage:
      'User {param} is an Compliance Officer. You cannot archive this type of user',
    description: 'User is an officer error toast',
  },
  reportUpdated: {
    id: 'ToastMessages.reportUpdated',
    defaultMessage: 'Report has been updated.',
    description: 'Update notification',
  },
  reportAnonymized: {
    id: 'ToastMessages.reportAnonymized',
    defaultMessage: 'Report has been anonymised.',
    description: 'Anonymization toast message',
  },
  reportAnonymizationReverted: {
    id: 'ToastMessages.reportAnonymizationReverted',
    defaultMessage: 'Anonymisation has been reverted.',
    description: 'Anonymization revert toast message',
  },
  receiveInfoReminderDateError: {
    id: 'ToastMessages.receiveInfoReminderDateError',
    defaultMessage: 'Reminder date cannot be in the past',
    description: 'Error handling for misconduct reminders',
  },
  summaryReminderDateError: {
    id: 'ToastMessages.summaryReminderDateError',
    defaultMessage: 'Reminder date cannot be in the past',
    description: 'Error handling for misconduct reminders',
  },
  archiveDateError: {
    id: 'ToastMessages.archiveDateError',
    defaultMessage: 'Final archiving date cannot be in the past',
    description: 'Error handling for misconduct archivie',
  },
  reportArchived: {
    id: 'ToastMessages.reportArchived',
    defaultMessage: 'Report has been archived.',
    description: 'Archive notification',
  },
  reportRestored: {
    id: 'ToastMessages.reportRestored',
    defaultMessage: 'Report has been de-archived (restored).',
    description: 'Archive Restore notification',
  },
  archiveReminderDateError: {
    id: 'ToastMessages.archiveReminderDateError',
    defaultMessage: 'Final archiving reminder date cannot be in the past',
    description: 'Archive reminder interval notification',
  },
  misconductOwnerNeededForReceiveInfoReminder: {
    id: 'ToastMessages.misconductOwnerNeededForReceiveInfoReminder',
    defaultMessage:
      'Compliance Officer need to be assigned to the report to set reminders',
    description: 'Reminder Error handler',
  },
  misconductOwnerNeededForSummaryReminder: {
    id: 'ToastMessages.misconductOwnerNeededForSummaryReminder',
    defaultMessage:
      'Compliance Officer need to be assigned to the report to set reminders',
    description: 'Reminder Error handler',
  },
});
