import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  header: {
    id: 'FloatingChat.header',
    defaultMessage: 'Chat with Compliance Officer',
    description: 'Support Chat header',
  },
  escalateToCO: {
    id: 'FloatingChat.escalateToCO',
    defaultMessage: 'Escalate to CO',
    description: 'Support Chat escalate to CO button text',
  },
  finishThread: {
    id: 'FloatingChat.finishThread',
    defaultMessage: 'Finish thread',
    description: 'Support Chat finish thread button text',
  },
});
