import React from 'react';
import PropTypes from 'prop-types';

import { getFileFormat } from 'helpers';
import { filePropTypes } from 'models';
import { useFetchFileUrl } from 'helpers/hooks';

import DownloadIcon from 'components/Svg/DownloadIcon';
import FileBadge from 'components/FileBadge';
import CloseIcon from 'components/Svg/CloseIcon';

import {
  FileBox,
  FileName,
  DownloadButton,
  CloseIconWrapper,
  AnonymizeButton,
} from './style';
import { useDispatch, useSelector } from 'react-redux';
import {
  setReportChanged,
  updateWhistleblowerAnonymizedFileIds,
} from 'stores/reportDetails';
import AnonymizeFileIcon from 'components/Svg/AnonymizeFileIcon';
import { useIntl } from 'react-intl';
import { reportMessages } from 'translations';
import { getDateFromNow } from 'helpers/functions';

const propTypes = {
  file: filePropTypes.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func,
  withDownloadIcon: PropTypes.bool,
  fileUrlResolver: PropTypes.func,
  deleteFile: PropTypes.func,
  misconductId: PropTypes.string,
  allowDelete: PropTypes.bool,
  isAnonymizable: PropTypes.bool,
};

const FileItem = ({
  file,
  children,
  fileUrlResolver,
  withDownloadIcon,
  deleteFile,
  misconductId,
  isAnonymizable,
  allowDelete = true,
}) => {
  const {
    id,
    name,
    anonymizedAt,
    extension,
    url,
    is_search_indexed,
    last_search_indexed_at,
  } = file;
  const dispatch = useDispatch();
  const fileUrl = useFetchFileUrl(fileUrlResolver, id, misconductId) || url;
  const { whistleblowerAnonymizedFileIds } = useSelector((state) => state.reportDetails);
  const intl = useIntl();

  const isInitialAnonymized = anonymizedAt;
  const anonymized = whistleblowerAnonymizedFileIds.includes(id);

  const anonymizeFile = (id) => {
    if (!anonymized) {
      dispatch(
        updateWhistleblowerAnonymizedFileIds([...whistleblowerAnonymizedFileIds, id])
      );
    } else {
      dispatch(
        updateWhistleblowerAnonymizedFileIds(
          whistleblowerAnonymizedFileIds.filter((item) => item !== id)
        )
      );
    }
    dispatch(setReportChanged(true));
  };

  return (
    <FileBox anonymized={anonymized || isInitialAnonymized}>
      <FileName
        href={!anonymizedAt && fileUrl}
        rel="noopener noreferrer"
        target="_blank"
        download={!anonymizedAt}
      >
        {is_search_indexed && (
          <FileBadge>{getDateFromNow(last_search_indexed_at)}</FileBadge>
        )}
        <FileBadge>{extension ? extension : getFileFormat(name)}</FileBadge>
        {isInitialAnonymized && `[${intl.formatMessage(reportMessages.anonymized)}]`}{' '}
        {name}
      </FileName>

      {children}

      {!deleteFile && withDownloadIcon && !anonymized && !isInitialAnonymized && (
        <DownloadButton
          href={!anonymizedAt && fileUrl}
          rel="noopener noreferrer"
          target="_blank"
          download={!anonymizedAt}
        >
          <DownloadIcon />
        </DownloadButton>
      )}

      {isAnonymizable && !isInitialAnonymized && (
        <AnonymizeButton onClick={() => anonymizeFile(id)}>
          <AnonymizeFileIcon />
        </AnonymizeButton>
      )}

      {deleteFile && allowDelete && (
        <CloseIconWrapper onClick={() => deleteFile(id)}>
          <CloseIcon />
        </CloseIconWrapper>
      )}
    </FileBox>
  );
};

FileItem.propTypes = propTypes;

export default FileItem;
