import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { ROUTING } from 'routing';

import AuthRoute from 'components/AuthRoute';
import PropTypes from 'prop-types';
import { getOfficerRoles, hasRoleRights } from '../../stores/auth';

const propTypes = {
  modules: PropTypes.array,
};

const CoRoute = ({ modules, ...props }) => {
  const { hasExtendedViewRights, roles } = useSelector((state) => state.auth);

  const officerRoles = getOfficerRoles(roles);
  const hasRole = modules ? hasRoleRights(officerRoles, modules) : true;

  return hasExtendedViewRights && hasRole ? (
    <AuthRoute {...props} />
  ) : (
    <Redirect to={ROUTING.INDEX} />
  );
};

CoRoute.propTypes = propTypes;

export default CoRoute;
