import { ROUTING } from 'routing';

const getContext = (route) => route.replace(':id', '');

export const toastType = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ALERT: 'alert',
  INFO: 'info',
};

export const toastContext = {
  DOCUMENTS: ROUTING.DOCUMENTS,
  NEW_DOCUMENT: ROUTING.NEW_DOCUMENT,
  DOCUMENT_EDIT: getContext(ROUTING.DOCUMENT_EDIT),
  DASHBOARD_CO: ROUTING.DASHBOARD_CO,
  DASHBOARD_EMPLOYEE: ROUTING.DASHBOARD_EMPLOYEE,
  USERS_MANAGEMENT: ROUTING.USER_MANAGEMENT,
  GROUPS_MANAGEMENT: ROUTING.GROUPS,
  GROUPS_DETAILS: getContext(ROUTING.GROUPS_DETAILS),
  CATEGORIES_MANAGEMENT: ROUTING.CATEGORIES,
  ADD_GROUP: ROUTING.GROUPS_ADD_NEW,
  MANUAL_IMPORT: ROUTING.MANUAL_IMPORT,
  REPORT_MANAGEMENT: getContext(ROUTING.REPORT_PREVIEW),
  REPORT_MANAGEMENT_AS_USER: getContext(ROUTING.USER_MISCONDUCT_DETAILS),
  USER_DETAILS: getContext(ROUTING.USER_DETAILS),
  ACCOUNT: getContext(ROUTING.ACCOUNT),
};
