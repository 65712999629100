import PropTypes from 'prop-types';

import { statisticTypes } from 'helpers/commonConstants';
import { toastType } from 'helpers/toastConstants';
import { priorities, officerAvailableStatuses } from 'helpers/reportConstants';
import { eventTypes } from 'helpers/eventNotificationMessageConstants';
import { validateEventNotificationData } from 'helpers/validateEventNotificationData';
import { validateLogEntryData } from 'helpers/validateLogEntryData';

const priorityPropTypes = priorities.map((item) => item.value);
const statusPropTypes = officerAvailableStatuses.map((item) => item.value);

export const userPropTypes = PropTypes.shape({
  id: PropTypes.string,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  created_at: PropTypes.string.isRequired,
  import_id: PropTypes.string,
  active: PropTypes.bool,
  officer: PropTypes.bool,
});

export const groupPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  users_count: PropTypes.number.isRequired,
  docs_assigned_count: PropTypes.number.isRequired,
});

export const categoryPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  documents_count: PropTypes.number.isRequired,
  date_created: PropTypes.string.isRequired,
});

export const reportPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired,
  reportState: PropTypes.shape({
    assignee: PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      officer: PropTypes.bool.isRequired,
    }),
    status: PropTypes.oneOf([...statusPropTypes]).isRequired,
    priority: PropTypes.oneOf([...priorityPropTypes]).isRequired,
  }).isRequired,
  reportInfo: PropTypes.object.isRequired,
});

export const commonDocumentShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  date_added: PropTypes.string.isRequired,
  due_date: PropTypes.string.isRequired,
};

export const userDocumentPropTypes = PropTypes.shape({
  ...commonDocumentShape,
  date_confirmed: PropTypes.string,
});

export const documentRecipientGroupShape = {
  id: PropTypes.string,
  name: PropTypes.string,
};

export const documentRecipientUserShape = {
  id: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
};

export const accessManegementUserShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  status: PropTypes.string,
};

export const sendReminderStatusProp = PropTypes.oneOf([
  'available',
  'unavailable',
  'reminder_sent',
]);

export const coDocumentPropTypes = PropTypes.shape({
  ...commonDocumentShape,
  owner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    officer: PropTypes.bool.isRequired,
  }).isRequired,
  confirmations: PropTypes.shape({
    confirmed: PropTypes.number,
    all: PropTypes.number,
  }),
  send_reminder_status: sendReminderStatusProp,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      extension: PropTypes.string,
    })
  ),
  groups: PropTypes.arrayOf(PropTypes.shape(documentRecipientGroupShape)),
  users: PropTypes.arrayOf(PropTypes.shape(documentRecipientUserShape)),
});

export const documentFormShape = PropTypes.shape({
  name: PropTypes.string,
  owner: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  due_date: PropTypes.string,
  description: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      extension: PropTypes.string,
    })
  ),
  groups: PropTypes.arrayOf(PropTypes.shape(documentRecipientGroupShape)),
  users: PropTypes.arrayOf(PropTypes.shape(documentRecipientUserShape)),
});

export const dataPortionShape = {
  label: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
};

export const notificationShape = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  event_name: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  is_viewed: PropTypes.bool,
  data: PropTypes.object, // this shape has to be consulted with backend for each event_type
};

export const listMetaShape = {
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export const notificationDataShape = {
  type: PropTypes.oneOf(Object.values(toastType)).isRequired,
  data: PropTypes.shape({}), // this shape has to be consulted with backend for each type
};

export const notificationComponentShape = {
  ...notificationDataShape,
  children: PropTypes.node,
  isNew: PropTypes.bool,
  marginBottom: PropTypes.number,
};

export const intlMessageProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  description: PropTypes.string,
});

export const coStatisticPropTypes = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(statisticTypes)).isRequired.isRequired,
  progressed: PropTypes.number.isRequired,
  remaining: PropTypes.number.isRequired,
});

export const filePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  anonymizedAt: PropTypes.string,
  extension: PropTypes.string.isRequired,
  url: PropTypes.string,
});

const availableEvents = Object.values(eventTypes);
export const eventNotificationMessagePropTypes = {
  eventName: PropTypes.oneOf(availableEvents).isRequired,
  data: validateEventNotificationData,
};

export const logPropShape = {
  date: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  data: validateLogEntryData,
};

export const recipientPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
});

export const recipientListPropTypes = PropTypes.arrayOf(recipientPropTypes);
