import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { getDateStr } from 'helpers';
import { updateChatroomMessage, setChatTouched } from 'stores/chatrooms';

import Attachment from 'components/Svg/Attachment';
import UserAvatar, { avatarSize } from 'components/UserAvatar';
import Button from 'components/Button';

import { reportMessages } from 'translations';

import {
  MessageWrapper,
  ContentWrapper,
  Text,
  File,
  Time,
  ChatAnonymizeTextArea,
} from '../style';
import AIAvatar from 'components/AIAvatar';

const propTypes = {
  message: PropTypes.object,
  isOutcoming: PropTypes.bool,
  isDark: PropTypes.bool,
  fileUrlResolver: PropTypes.func,
  roomId: PropTypes.string,
  whistleblowerPerspective: PropTypes.bool,
};

const Message = ({
  message: { id, user, text, file, created_at },
  isOutcoming,
  fileUrlResolver,
  isDark,
  roomId,
  whistleblowerPerspective,
}) => {
  const [fileUrl, setFileUrl] = useState('');
  const fileLabel = `${file?.name}.${file?.extension}`;
  const intl = useIntl();
  const textArea = useRef(null);
  const dispatch = useDispatch();
  const { anonymizationStarted } = useSelector((state) => state.reportDetails);

  const getFileUrl = async (evt) => {
    if (fileUrl.length === 0) {
      evt.preventDefault();

      if (!file?.anonymized_at || whistleblowerPerspective)
        await fileUrlResolver(file?.id).then(({ data }) => {
          window.open(data);
          setFileUrl(data);
        });
    }
  };

  const updateChatMessage = (roomId, id, value) => {
    dispatch(updateChatroomMessage({ roomId, id, value }));
    dispatch(setChatTouched(true));
  };

  const anonymizeSelected = () => {
    const length = textArea.current.selectionEnd - textArea.current.selectionStart;
    const originalText = textArea.current.value;
    const newValue =
      originalText.substring(0, textArea.current.selectionStart) +
      '█'.repeat(length) +
      originalText.substring(textArea.current.selectionEnd, originalText.length);

    updateChatMessage(roomId, id, newValue);
  };

  return (
    <MessageWrapper isOutcoming={isOutcoming}>
      {user.id === 'AI' ? (
        <AIAvatar size={avatarSize.MEDIUM} />
      ) : (
        <UserAvatar size={avatarSize.MEDIUM} user={user} />
      )}

      <ContentWrapper isOutcoming={isOutcoming} fullWidth={anonymizationStarted}>
        {anonymizationStarted && text ? (
          <>
            <ChatAnonymizeTextArea
              id={roomId}
              ref={textArea}
              value={text}
              rtl={isOutcoming}
              onChange={() => {}}
            />

            <Button primary isSmall onClick={anonymizeSelected}>
              {intl.formatMessage(reportMessages.anonymizeFragment)}
            </Button>
          </>
        ) : (
          <>
            {text ? (
              <Text isOutcoming={isOutcoming} isDark={isDark}>
                {text}
              </Text>
            ) : (
              <File
                as="a"
                href={
                  whistleblowerPerspective ? fileUrl : !file?.anonymized_at && fileUrl
                }
                onClick={getFileUrl}
                target="_blank"
                rel="noopener noreferrer"
                download
                isOutcoming={isOutcoming}
                isDark={isDark}
                disabled={!whistleblowerPerspective && file?.anonymized_at}
              >
                <Attachment /> {fileLabel}
              </File>
            )}

            <Time isDark={isDark}>{getDateStr(created_at, 'MMM DD, YYYY HH:mm')}</Time>
          </>
        )}
      </ContentWrapper>
    </MessageWrapper>
  );
};

Message.propTypes = propTypes;

export default Message;
