import { urlsWithEnabledRetry, useApi } from 'api';
import axios from 'axios';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isAuthenticatedOfficer } from './stores/auth';

export const InterceptorContext = createContext();

const propTypes = {
  children: PropTypes.node,
};

export const InterceptorProvider = ({ children }) => {
  const {
    properties: { logoutTimeEmployee, logoutTimeOfficer },
  } = useSelector((state) => state.tenantProperties);
  const auth = useSelector((state) => state.auth);

  const hasOfficerRights = isAuthenticatedOfficer(auth);

  const getDate = (time) => {
    return Date.now() + time * 1000;
  };

  const [time, setTime] = useState(getDate(logoutTimeEmployee));
  const { api } = useApi();

  useEffect(() => {
    if (api?.instance?.interceptors.response) {
      api.instance.interceptors.response.use(
        function (response) {
          setTime(
            hasOfficerRights ? getDate(logoutTimeOfficer) : getDate(logoutTimeEmployee)
          );
          return response;
        },
        function (error) {
          const originalRequest = error.config;
          if (
            error.response.status === 423 &&
            urlsWithEnabledRetry.includes(error.config.url)
          ) {
            return axios(originalRequest);
          }

          if (
            error.response.status === 403 &&
            urlsWithEnabledRetry.includes(error.config.url)
          ) {
            if (!document.cookie.includes('csrftoken=')) {
              return api.config.getConfig().then(() => axios(originalRequest));
            }
            return axios(originalRequest);
          }
          return Promise.reject(error);
        }
      );
    }
  }, [api, hasOfficerRights, logoutTimeEmployee, logoutTimeOfficer]);

  return (
    <InterceptorContext.Provider value={{ time, setTime }}>
      {children}
    </InterceptorContext.Provider>
  );
};

InterceptorProvider.propTypes = propTypes;

export const useInterceptorContext = () => {
  const context = useContext(InterceptorContext);

  if (context === undefined) {
    throw new Error('useInterceptorContext must be used within a InterceptorProvider');
  }
  return context;
};
