export const eventTypes = {
  REPORT_CREATED: 'report_created',
  MISCONDUCT_SHARING_ADDED: 'misconduct_sharing_added',
  MISCONDUCT_SHARING_USER_ASSIGNED: 'misconduct_sharing_user_assigned',
  MISCONDUCT_INFORM_WHISTLEBLOWER_REMINDER:
    'inform_misconduct_whistleblower_reminder_sent',
  MISCONDUCT_CO_SUMMARY_REMINDER: 'misconduct_summary_reminder_sent',
  REPORT_NEW_MESSAGE: 'new_report_message_sent',
  DOCUMENT_ASSIGNED: 'new_document_assigned',
  MISCONDUCT_PERMISSIONS_CHANGED: 'misconduct_user_permissions_changed',
  NEW_OWNED_DOCUMENT: 'new_owned_document',
  DOCUMENT_VERSION_CREATED: 'document_version_created',
  GROUP_USER_ADDED: 'group_user_added',
  MISCONDUCT_ARCHIVE_REMINDER_SENT: 'misconduct_archive_reminder_sent',
  INTERNAL_CHAT_NEW_MESSAGE: 'new_internal_message_sent',
  CHAT_SUSPENDED: 'chat_suspended',
};

export const dataTypeShapes = {
  [eventTypes.REPORT_CREATED]: {
    report_id: 'string',
    report_name: 'string',
  },
  [eventTypes.MISCONDUCT_INFORM_WHISTLEBLOWER_REMINDER]: {
    report_id: 'string',
    report_name: 'string',
  },
  [eventTypes.MISCONDUCT_CO_SUMMARY_REMINDER]: {
    report_id: 'string',
    report_name: 'string',
  },
  [eventTypes.MISCONDUCT_PERMISSIONS_CHANGED]: {
    report_id: 'string',
    report_name: 'string',
  },
  [eventTypes.MISCONDUCT_SHARING_USER_ASSIGNED]: {
    report_id: 'string',
    report_name: 'string',
  },
  [eventTypes.REPORT_NEW_MESSAGE]: {
    report_id: 'string',
    report_name: 'string',
  },
  [eventTypes.MISCONDUCT_ARCHIVE_REMINDER_SENT]: {
    report_id: 'string',
    report_name: 'string',
  },
  [eventTypes.DOCUMENT_ASSIGNED]: {
    document_id: 'string',
    document_name: 'string',
  },
  [eventTypes.NEW_OWNED_DOCUMENT]: {
    document_id: 'string',
    document_name: 'string',
  },
  [eventTypes.DOCUMENT_VERSION_CREATED]: {
    document_id: 'string',
    document_name: 'string',
  },
  [eventTypes.GROUP_USER_ADDED]: {
    group_id: 'string',
    group_name: 'string',
  },
  [eventTypes.INTERNAL_CHAT_NEW_MESSAGE]: {
    group_id: 'string',
    group_name: 'string',
  },
  [eventTypes.CHAT_SUSPENDED]: {
    chat_id: 'string',
  },
};
