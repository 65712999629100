import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { usePrevious } from 'helpers/hooks';
import { isDeeplyEqual } from 'helpers';

import Message from './parts/Message';
import EmptyState from './parts/EmptyState';

import { ChatWrapper, MessageBox } from './style';
import { colors } from 'styles';
import WelcomeMessage from './parts/WelcomeMessage';
import MessageWithAnswers from './parts/MessageWithAnswers';
import ThreadDivider from './parts/ThreadDivider';
import { useSelector } from 'react-redux';
import TypingDotsMessage from './parts/TypingDotsMessage';
import ThreadWillEndMessage from './parts/ThreadWillEndMessage';
import DocumentLinkMessage from './parts/DocumentLinkMessage';
import { isAuthenticatedOfficer } from '../../stores/auth';

const propTypes = {
  messages: PropTypes.array,
  streamingMessage: PropTypes.string,
  isDark: PropTypes.bool,
  backgroundColor: PropTypes.string,
  roomId: PropTypes.string,
  fileUrlResolver: PropTypes.func.isRequired,
  refreshMessages: PropTypes.func.isRequired,
  pauseRefresh: PropTypes.bool,
  outgoingMessageTypeString: PropTypes.string,
  whistleblowerPerspective: PropTypes.bool,
  sendHandler: PropTypes.func.isRequired,
};

const defaultProps = {
  outgoingMessageTypeString: 'outgoing',
};

const REFRESH_PERIOD_MS = 30000;

export const ChatMessages = ({
  messages,
  streamingMessage,
  isDark,
  backgroundColor,
  fileUrlResolver,
  refreshMessages,
  roomId,
  pauseRefresh = false,
  outgoingMessageTypeString,
  whistleblowerPerspective = false,
  sendHandler,
}) => {
  const conversationStarted = !!messages?.length;
  const messageBoxRef = useRef(null);
  const previousMessages = usePrevious(messages);
  const auth = useSelector((state) => state.auth);
  const hasOfficerRights = isAuthenticatedOfficer(auth);
  const { aiTyping } = useSelector((state) => state.chatrooms);

  const lastMessage = conversationStarted && messages[messages.length - 1];

  useEffect(() => {
    if (!isDeeplyEqual(messages, previousMessages)) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  }, [messageBoxRef, previousMessages, messages]);

  useEffect(() => {
    refreshMessages();
  }, [refreshMessages]);

  useEffect(() => {
    const autoRefresh = setInterval(refreshMessages, REFRESH_PERIOD_MS);

    if (pauseRefresh) {
      clearInterval(autoRefresh);
    }
    return () => clearInterval(autoRefresh);
  }, [refreshMessages, pauseRefresh]);

  const getMessageVariant = (message) => {
    switch (message.message_variant) {
      case 'THREAD_START':
        return <WelcomeMessage key={message.id} message={message.text} />;

      case 'THREAD_END':
        return <ThreadDivider key={message.id} />;

      case 'MESSAGE':
      case 'MESSAGE_AI':
        return (
          <Message
            roomId={roomId}
            key={message.id}
            message={message}
            isOutcoming={message.type === outgoingMessageTypeString}
            fileUrlResolver={fileUrlResolver}
            isDark={isDark}
            whistleblowerPerspective={whistleblowerPerspective}
          />
        );

      case 'MESSAGE_AI_WITH_OPTIONS':
        return (
          <MessageWithAnswers
            key={message.id}
            message={message}
            sendHandler={sendHandler}
            isOutcoming={message.type === outgoingMessageTypeString}
            disabled={message.id !== lastMessage.id || hasOfficerRights}
          />
        );

      case 'REF_DOCUMENTS':
        return <DocumentLinkMessage message={message} />;

      default:
        return <></>;
    }
  };
  const isStreamingMessage = !!streamingMessage;

  return (
    <ChatWrapper
      backgroundColor={backgroundColor || (isDark ? colors.mako : colors.white)}
    >
      <MessageBox ref={messageBoxRef}>
        {messages && !hasOfficerRights && (
          <ThreadWillEndMessage lastMessageDate={lastMessage.created_at} />
        )}
        {(isStreamingMessage || aiTyping) && (
          <TypingDotsMessage isOutcoming={hasOfficerRights} message={streamingMessage} />
        )}
        {conversationStarted ? (
          [...messages].reverse().map((message) => getMessageVariant(message))
        ) : (
          <EmptyState />
        )}
      </MessageBox>
    </ChatWrapper>
  );
};

ChatMessages.propTypes = propTypes;
ChatMessages.defaultProps = defaultProps;
