import React from 'react';
import { useIntl } from 'react-intl';

import {
  ThreadDividerBox,
  ThreadDividerBoxWrapper,
  ThreadDividerWrapper,
} from '../style';
import { messages } from '../messages';

const ThreadDivider = () => {
  const intl = useIntl();

  return (
    <ThreadDividerWrapper>
      <ThreadDividerBoxWrapper>
        <ThreadDividerBox>
          {intl.formatMessage({ ...messages.threadEnded })}
        </ThreadDividerBox>
      </ThreadDividerBoxWrapper>
    </ThreadDividerWrapper>
  );
};

export default ThreadDivider;
