import axios from 'axios';
import { appendQueryParams } from 'helpers/appendQueryParams';
import { createQueryParams } from 'helpers';
import { createContext, useContext } from 'react';
import {
  deserializeUser,
  deserializeGroup,
  deserializeDocument,
  deserializeRecipient,
  deserializeDocumentVersion,
  serializeUserGroups,
  deserializeCategory,
} from 'deserializers';
import configFile from './sanctions-config.json';

export const ApiContext = createContext({});

export const useApi = () => {
  return useContext(ApiContext);
};

const qs = require('qs');

const abortController = new AbortController();

export const urlsWithEnabledRetry = [
  '/misconducts/survey/start',
  '/misconducts/survey/fetch',
  '/misconducts/survey/update',
  '/misconducts/survey/next_step',
  '/misconducts/survey/previous_step',
  '/misconducts/survey/attachments',
  '/misconducts/survey/submit',
];

export const api = axios.create({
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

export let cancel;

const auth = {
  resetPassword: ({ token, password }) => {
    return api.post('/auth/reset_password', {
      reset_password_token: token,
      password,
    });
  },
  forgotPassword: (email) => {
    return api.post('/auth/forgot_password', { email });
  },
  signOut: (scope) => {
    return api.post('/auth/sign_out', { scope });
  },
  signIn: ({ email, password, scope }) => {
    return api.post('/auth/sign_in', { email, password, scope });
  },
  acceptInvitation: ({ token, password }) => {
    return api.post('/auth/accept_invitation', {
      invitation_token: token,
      password: password,
    });
  },
  getAuthToken: (email) => {
    return api.post('/auth/send_code', { email });
  },
  logInViaToken: ({ email, token, scope }) => {
    return api.post('/auth/sign_in_second_step', { email, code: token, scope });
  },
};

const coDashboard = {
  // getDashboard: async () => {
  //   const response = await api.get('/dashboard/officer');
  //   return {
  //     ...response,
  //     data: {
  //       ...response.data,
  //       statisticsFilterOptions: {
  //         donut: [
  //           { value: 'donut-1', label: 'Wysłane' },
  //           { value: 'donut-2', label: 'Przyjęte' },
  //           { value: 'donut-3', label: 'W toku' },
  //           { value: 'donut-4', label: 'Zamknięte (potwierdzone)' },
  //           { value: 'donut-5', label: 'Zamknięte (niepotwierdzone)' },
  //           { value: 'donut-6', label: 'Zamknięte (częściowo potwierdzone)' },
  //           { value: 'donut-7', label: 'Zamknięte (nie dotyczy)' },
  //         ],
  //       },
  //     },
  //   };
  // },
  getDashboard: () => {
    return api.get('/dashboard/officer');
  },

  getDonutStatistics: (queryParams) => {
    const queryString = qs.stringify(queryParams, {
      encode: false,
      arrayFormat: 'brackets',
    });
    return api.get(`/dashboard/statistics?${queryString}`);
  },

  getBarStatistics: (queryParams) => {
    const queryString = qs.stringify(queryParams, {
      encode: false,
      arrayFormat: 'brackets',
    });
    return api.get(`/dashboard/statistics?${queryString}`);
  },

  getStatisticsGroups: () => {
    return api.get(`/dashboard/statistics_groups`);
  },
};

const userDashboard = {
  getDashboard: () => {
    return api.get('/dashboard/user');
  },
};

const extendedUserDashboard = {
  getDashboard: () => {
    return api.get('/dashboard/extended_user');
  },
};

const documents = {
  getDocuments: (queryParams = {}) => {
    const queryString = qs.stringify(queryParams, {
      encode: false,
      arrayFormat: 'brackets',
    });

    return api.get(`/documents?${queryString}`);
  },
  getDocumentsExport: () => {
    return '/documents/export';
  },
  getDocumentsLogExport: (documentId) => {
    return `/documents/${documentId}/export`;
  },
  uploadFile: (file) => {
    return quarantine.uploadFileAndWaitForStatus({ file });
  },
  archiveDocument: async (id) => {
    const response = await api.put(`/documents/${id}/archive`);
    return deserializeDocument(response.data);
  },
  restoreDocument: async (id) => {
    const response = await api.put(`/documents/${id}/restore`);
    return deserializeDocument(response.data);
  },
  reindexDocument: async (id) => {
    return await api.post(`/documents/${id}/index_files`);
  },
  deleteDocument: (id) => {
    return api.delete(`/documents/${id}`);
  },
  createDocument: async ({
    name,
    owner_id,
    category_id,
    description,
    due_date,
    alerts,
    groups,
    users,
    file_ids,
  }) => {
    const response = await api.post('/documents', {
      name,
      owner_id,
      category_id,
      description,
      due_date,
      alerts,
      groups,
      users,
      file_ids,
    });
    return response.data;
  },
  editDocument: (
    document_id,
    {
      name,
      owner_id,
      category_id,
      description,
      due_date,
      groups,
      alerts,
      users,
      file_ids,
    }
  ) => {
    return api.put(`/documents/${document_id}/edit`, {
      name,
      owner_id,
      category_id,
      description,
      alerts,
      due_date,
      groups,
      users,
      file_ids,
    });
  },
  sendReminder: (documentId) => {
    return api.put(`/document_recipients/${documentId}/send_reminders`);
  },
  createNewVersion: (documentId, { dueDate, files }) => {
    return api.post(`/documents/${documentId}/new_version`, {
      due_date: dueDate,
      file_ids: files.map(({ id }) => id),
    });
  },
  getDocument: async (id) => {
    const response = await api.get(`/documents/${id}`);
    return deserializeDocument(response.data);
  },
  getRecipients: (id, queryParams = {}) => {
    const params = createQueryParams(queryParams);
    return api.get(`/document_recipients/${id}`, { params });
  },
  getFileUrl: (id) => {
    return api.get(`/documents/files/${id}/download_path`);
  },
  getLogs: ({ id, page, perPage }) => {
    const queryParams = { page, perPage };
    return api.get(appendQueryParams(`documents/${id}/log`, queryParams));
  },
  getVersions: async (id) => {
    const response = await api.get(`/documents/${id}/versions`);
    return response.data.map(deserializeDocumentVersion);
  },
};

const categories = {
  getCategories: (queryParams = {}) => {
    return api.get(appendQueryParams('/categories', queryParams));
  },
  addCategory: (name) => {
    return api.post(`/categories`, {
      name,
    });
  },
  editCategory: (id, data) => {
    return api.put(`/categories/${id}`, data);
  },

  deleteCategory: (id) => {
    return api.delete(`/categories/${id}`);
  },
};

const groups = {
  getGroups: (queryParams = {}) => {
    return api.get(appendQueryParams('/groups', queryParams));
  },
  getGroupLogExport: (groupId) => {
    return `/groups/${groupId}/export`;
  },
  addGroup: (name, users) => {
    return api.post(`/groups`, {
      name,
      user_ids: users,
    });
  },
  deleteGroup: (groupId) => {
    return api.delete(`/groups/${groupId}`);
  },
  getGroupDetails: (groupId) => {
    return api.get(`/groups/${groupId}`);
  },
  editGroupDetails: (groupId, name, usersToDelete) => {
    const userIds = usersToDelete.length ? { user_ids_to_delete: usersToDelete } : {};
    const groupName = name ? { name } : {};

    return api.put(`/groups/${groupId}/edit`, {
      ...userIds,
      ...groupName,
    });
  },
  addUsers: (groupId, newUsers) => {
    return api.post(`/groups/${groupId}/add_users`, {
      user_ids: newUsers,
    });
  },
  getGroupUsers: ({ id, page, perPage }) => {
    const queryParams = { page, perPage };
    return api.get(appendQueryParams(`/groups/${id}/users`, queryParams));
  },
  getGroupRules: (id) => {
    return api.get(`/automation/groups/${id}/rules`);
  },
  saveGroupRules: ({ id, rules, permissions, applyRules }) => {
    const data = { rules, permissions, apply_rules: applyRules };
    return api.put(`/automation/groups/${id}/rules`, data);
  },
};

const me = {
  updateLanguage: (language) => {
    return api.put('/me/language', { language });
  },
  updatePassword: ({ currentPassword, password, passwordConfirmation }) => {
    return api.put('/me/password', {
      current_password: currentPassword,
      password,
      password_confirmation: passwordConfirmation,
    });
  },
  getMyInfo: () => {
    return api.get('/me');
  },
  getMyDocuments: (queryParams = {}) => {
    const queryString = qs.stringify(queryParams, {
      encode: false,
      arrayFormat: 'brackets',
    });

    return api.get(`/me/documents?${queryString}`);
  },
  getMyMisconducts: (queryParams = {}) => {
    const queryString = qs.stringify(queryParams, {
      encode: false,
      arrayFormat: 'brackets',
    });
    return api.get(`/me/misconducts?${queryString}`);
  },
  signDocument: (documentId) => {
    return api.post(`/me/documents/${documentId}/sign`);
  },
  getOwnedDocuments: (queryParams = {}) => {
    const queryString = qs.stringify(queryParams, {
      encode: false,
      arrayFormat: 'brackets',
    });

    return api.get(`/me/owned_documents?${queryString}`);
  },
  getDocumentPreview: (documentId) => {
    return api.get(`/me/documents/${documentId}`);
  },
  getMisconductDetails: (misconductId) => {
    return api.get(`/me/misconducts/${misconductId}`);
  },
  getDocumentOwnerPreview: (documentId) => {
    return api.get(`me/owned_documents/${documentId}`);
  },
  getDocumentFileUrl: async (fileId, docId) => {
    const response = await api.get(
      `/me/documents/${docId}/files/${fileId}/download_path`
    );
    return response.data;
  },
  getChatMessages: () => {
    return api.get('/me/chat/messages');
    // return Promise.resolve({
    //   data: chatUserAIMessagesArray,
    // });
  },
  escalateToCO: () => {
    return api.post('/me/chat/suspend_ai_chat');
  },
  getChatStatus: () => {
    return api.get('/me/chat/status');
  },
  postChatMessage: (text) => {
    return api.post('/me/chat/send_message', { text });
  },
  postChatFile: async (file) => {
    const savedFileResponse = await quarantine.uploadFileAndWaitForStatus({ file });
    return api.post(`me/chat/send_file_message`, {
      attachment: { file_id: savedFileResponse.data.id },
    });
  },
  resolveFileUrl: async (file_id) => {
    return await api.get(`me/chat/files/${file_id}/download_path`);
  },
  setMisconductStatus: (id, { status, priority }) => {
    const data = { status, priority };
    return api.put(`/me/misconducts/${id}`, data);
  },
  finishChatThread: () => {
    return api.post(`me/chat/close_ai_chat`);
  },
};

const misconduct = {
  postAnswers: (data) => {
    return api.post('/misconducts', data);
  },
  fetchMyReportMessages: (token) => {
    return api.get('/misconducts/messages', {
      headers: {
        'X-Report-Token': token,
      },
    });
  },
  postMessageToMyReport: ({ token, text }) => {
    return api.post(
      'misconducts/create_message ',
      { text: text },
      {
        headers: {
          'X-Report-Token': token,
        },
      }
    );
  },
  getReportStatus: ({ reportToken, recaptchaToken }) => {
    return api.get('misconducts/check_status', {
      headers: {
        'X-Report-Token': reportToken,
      },
      params: {
        recaptcha_response: recaptchaToken || '',
      },
    });
  },
  followUpdates: ({ email, fullname, phone, token, recaptchaToken }) => {
    return api.post(
      '/misconducts/assign_details',
      {
        email,
        fullname,
        phone,
        recaptcha_response: recaptchaToken,
      },
      {
        headers: {
          'X-Report-Token': token,
        },
      }
    );
  },

  initializeSurvey: async (language, recaptchaToken) => {
    return api.post('/misconducts/survey/start', {
      language,
      recaptcha_response: recaptchaToken || null,
    });
  },

  getSurveyStep: async (language, response_id) => {
    return api.post(
      appendQueryParams('/misconducts/survey/fetch', {
        language,
        response_id: response_id,
      })
    );
  },

  getSurveySummary: async (language, response_id) => {
    return api.post(`misconducts/survey/summary`, {
      language,
      response_id: response_id,
    });
  },

  postCurrentStepAnswers: (data) => {
    return api.patch('misconducts/survey/update', data);
  },

  initializeSurveyNextStep: (id) => {
    return api.post('misconducts/survey/next_step', { response_id: id });
  },

  initializeSurveyPreviousStep: (id) => {
    return api.post('/misconducts/survey/previous_step', { response_id: id });
  },

  initializeSurveySpecificPreviousStep: (id, stepId) => {
    return api.post(`/misconducts/survey/previous_step/${stepId}`, { response_id: id });
  },

  postMisconductFiles: (data) => {
    return api.post('/misconducts/survey/attachments', data);
  },

  submitSurvey: (data) => {
    return api.post('/misconducts/survey/submit', data);
  },

  getCustomInstructions: (language) => {
    return api.get(
      appendQueryParams('/misconducts/send_report_instructions', { language })
    );
  },
  getCustomDocuments: () => {
    return api.get('/misconducts/documents_download_paths');
  },
  getChatFileAsAnonymous: ({ token, id }) => {
    return api.get(`/misconducts/messages/files/${id}/download_path`, {
      headers: {
        'X-Report-Token': token,
      },
    });
  },
  createFileMessageAsAnonymous: async ({ token, file }) => {
    const savedFileResponse = await quarantine.uploadFileAndWaitForStatus({ file });
    return api.post(
      `/misconducts/create_file_message`,
      {
        attachment: { file_id: savedFileResponse.data.id },
      },
      {
        headers: {
          'X-Report-Token': token,
        },
      }
    );
  },
  getReportDetails: (id) => {
    return api.get(`/misconducts/${id}`);
  },
  getReportFiles: (id, folders = []) => {
    return api.get(appendQueryParams(`/misconducts/${id}/files`, { folders }));
  },
  getWhistleblowerDetails: (id) => {
    return api.get(`/misconducts/${id}/whistleblower_details`);
  },
  getLogs: ({ id, page, perPage }) => {
    const queryParams = { page, perPage };
    return api.get(appendQueryParams(`misconducts/${id}/log`, queryParams));
  },
  getReportsList: (queryParams = {}) => {
    const queryString = qs.stringify(queryParams, {
      encode: false,
      arrayFormat: 'brackets',
    });
    return api.get(`/misconducts?${queryString}`);
  },
  getArchivedReportsList: (queryParams = {}) => {
    const queryString = qs.stringify(queryParams, {
      encode: false,
      arrayFormat: 'brackets',
    });
    return api.get(`/misconducts/archived?${queryString}`);
  },
  getMisconductsExport: () => {
    return '/misconducts/export';
  },
  getMisconductLogExport: (misconductId) => {
    return `/misconducts/${misconductId}/export`;
  },
  getChatFile: (id) => {
    return api.get(`/misconducts/message_files/${id}/download_path`);
  },
  getReportAccessDetails: (id) => {
    return api.get(`/misconducts/${id}/permissions`);
  },
  setReportAccessDetails: (id, users, groups) => {
    const serializedUsers = serializeUserGroups(users);
    const serializedGroups = serializeUserGroups(groups);

    const data = {
      users: serializedUsers,
      groups: serializedGroups,
    };
    return api.put(`/misconducts/${id}/permissions`, data);
  },
  setReportStates: (id, { status, priority, assignee }) => {
    const data = {
      status,
      priority: priority,
      assignee_id: assignee ? assignee.value : null,
    };
    return api.put(`/misconducts/${id}`, data);
  },
  uploadFile: (file) => {
    return quarantine.uploadFileAndWaitForStatus({ file });
  },
  getMisconductChatById: (id) => {
    return api.get(`/misconducts/${id}/messages`);
  },
  getMisconductInternalChatById: (id) => {
    return api.get(`/misconducts/${id}/internal_messages`);
  },
  createMessageForRoomId: ({ id, text }) => {
    return api.post(`/misconducts/${id}/create_message`, { text });
  },
  createMessageForInternalRoomId: ({ id, text }) => {
    return api.post(`/misconducts/${id}/internal_message`, { text });
  },
  archiveMisconduct: ({ id, interval, active, dueDate }) => {
    return api.put(`/misconducts/${id}/archive`, {
      archive_reminder: { interval, active },
      archive_date: dueDate,
    });
  },
  allowUploads: ({ id, allowed }) => {
    return api.put(`/misconducts/${id}/allow_chat_uploads`, { allowed });
  },
  restoreMisconduct: (id) => {
    return api.delete(`/misconducts/${id}/archive`);
  },
  createFileMessageForRoomId: async ({ id, file }) => {
    const savedFileResponse = await quarantine.uploadFileAndWaitForStatus({ file });
    return api.post(`/misconducts/${id}/create_file_message`, {
      attachment: { file_id: savedFileResponse.data.id },
    });
  },
  createFileMessageForInternalRoomId: async ({ id, file }) => {
    const savedFileResponse = await quarantine.uploadFileAndWaitForStatus({ file });
    return api.post(`/misconducts/${id}/internal_file_message`, {
      attachment: { file_id: savedFileResponse.data.id },
    });
  },
  getMisconductOfficerFiles: (id) => {
    return api.get(`/misconducts/${id}/officer_files`);
  },
  getMisconductInternalNote: (id) => {
    return api.get(`/misconducts/${id}/internal_note`);
  },
  updateMisconductInternalNote: (id, text) => {
    return api.put(`/misconducts/${id}/internal_note`, { text });
  },
  getFileUrl: (id) => {
    return api.get(`/misconducts/files/${id}/download_path`);
  },
  getWhistleblowerAttachementFile: (id) => {
    return api.get(`/misconducts/attachments/${id}/download_path`);
  },
  getMisconductFile: (fileId, misconductId) => {
    return api.get(`/misconducts/${misconductId}/files/${fileId}/url`);
  },
  anonymizeReport: ({ id, params, chat, internalChat, files }) => {
    return api.put(`/misconducts/${id}/anonymize`, {
      survey_answers: params,
      chat,
      internal_chat: internalChat || [],
      file_ids: files,
    });
  },
  revertReportAnonymization: (id) => {
    return api.delete(`/misconducts/${id}/anonymize`);
  },
  getReportReminders: (id) => {
    return api.get(`/misconducts/${id}/reminders`);
  },
  setReportReminders: (id, reminders) => {
    const data = reminders;
    return api.put(`/misconducts/${id}/reminders`, data);
  },
  setReportFiles: (id, files) => {
    return api.put(`/misconducts/${id}/update_files`, { file_ids: files });
  },
  getRightsKeys: () => {
    return api.get(`/access/permissions`);
  },
  getAssignableUsers: async (id, { query, excludedGroups = [], excludedUsers = [] }) => {
    const response = await api.get(`/misconducts/${id}/grantees`, {
      params: {
        query: query || undefined,
        exclude_group_ids: excludedGroups.map(extractId),
        exclude_user_ids: excludedUsers.map(extractId),
      },
    });
    return response.data.map(deserializeUser);
  },
  getAssignableGroupsAndUsers: async (
    id,
    { query, excludedGroups = [], excludedUsers = [] }
  ) => {
    const response = await api.get(`/misconducts/${id}/groups_and_users`, {
      params: {
        query: query || undefined,
        exclude_group_ids: excludedGroups.map(extractId),
        exclude_user_ids: excludedUsers.map(extractId),
      },
    });
    return response.data.map(deserializeRecipient);
  },
};

const notifications = {
  getNotifications: (queryParams = {}) => {
    return api.get(appendQueryParams('/notifications', queryParams));
  },
  markAsRead: (id) => {
    return api.post(`notifications/${id}/mark_as_read`);
  },
  markAllAsRead: () => {
    return api.post(`notifications/mark_all_as_read`);
  },
};

const search = {
  getUsers: async ({ query, excludedUsers = [] }) => {
    const response = await api.get('/search/users/', {
      params: {
        query: query || undefined,
        exclude_user_ids: excludedUsers.map(extractId),
      },
    });

    return response.data.map(deserializeUser);
  },
  getOfficers: async ({ query, excludedUsers = [] }) => {
    const response = await api.get('/search/users/', {
      params: {
        query: query || undefined,
        officer: true,
        exclude_user_ids: excludedUsers.map(extractId),
      },
    });

    return response.data.map(deserializeUser);
  },
  getGroups: async ({ query, excludedGroups = [] }) => {
    const response = await api.get('/search/groups/', {
      params: {
        query: query || undefined,
        exclude_group_ids: excludedGroups.map(extractId),
      },
    });
    return response.data.map(deserializeGroup);
  },
  getCategories: async ({ query, perPage }) => {
    const response = await api.get('/categories', {
      params: {
        query: query || undefined,
        per_page: perPage || 10,
      },
    });
    return response.data.items.map(deserializeCategory);
  },

  getMeCategories: async ({ query, perPage }) => {
    const response = await api.get('/me/categories', {
      params: {
        query: query || undefined,
        per_page: perPage || 10,
      },
    });
    return response.data.items.map(deserializeCategory);
  },
  getGroupsAndUsers: async ({ query, excludedGroups = [], excludedUsers = [] }) => {
    const response = await api.get('/search/groups_and_users/', {
      params: {
        query: query || undefined,
        exclude_group_ids: excludedGroups.map(extractId),
        exclude_user_ids: excludedUsers.map(extractId),
      },
    });
    return response.data.map(deserializeRecipient);
  },
};

const users = {
  getUsers: (queryParams = {}) => {
    return api.get(appendQueryParams('/users', queryParams));
  },
  getUsersExport: () => {
    return '/users/export';
  },
  getUsersXlsExport: () => {
    return '/users/export/xls';
  },
  getUsersCsvExport: () => {
    return '/users/export/csv';
  },
  getUserLogExport: (userId) => {
    return `/users/${userId}/export`;
  },
  activateUser: (id) => {
    return api.put(`/users/${id}/activate`);
  },
  deactivateUser: (id) => {
    return api.put(`/users/${id}/archive`);
  },
  getLogs: ({ id, page, perPage }) => {
    const queryParams = { page, perPage };
    return api.get(appendQueryParams(`users/${id}/log`, queryParams));
  },
  getUserDocuments: ({ id, page, perPage }) => {
    const queryParams = { page, perPage };
    return api.get(appendQueryParams(`/users/${id}/documents`, queryParams));
  },
  editUser: ({ id, ...userData }) => {
    return api.put(`/users/${id}/edit`, userData);
  },
  getUser: (id) => {
    return api.get(`/users/${id}`);
  },
  getUserRules: (id) => {
    return api.get(`/automation/users/${id}/rules`);
  },
  saveUserRules: ({ id, rules, permissions, applyRules }) => {
    const data = { rules, permissions, apply_rules: applyRules };
    return api.put(`/automation/users/${id}/rules`, data);
  },
};

const imports = {
  getCurrent: () => {
    return api.get('/users_imports/current');
  },
  getCurrentActions: (queryParams) => {
    return api.get(appendQueryParams('users_imports/current/actions', queryParams));
  },
  uploadFile: (file) => {
    const files = new FormData();
    files.append('attachment[employees]', file);
    return api.post('/users_imports', files);
  },
  manualImport: ({ firstName, lastName, email, groups }) => {
    return api.post('/users', {
      first_name: firstName,
      last_name: lastName,
      email,
      group_ids: groups,
    });
  },
  cancelImport: (id) => {
    return api.delete(`/users_imports/${id}`);
  },
  invokeImport: (id) => {
    return api.post(`/users_imports/${id}/invoke`);
  },
  getImportStatus: (id) => {
    return api.get(`/users_imports/${id}`);
  },
  STATUS: {
    SCHEDULED: 'scheduled',
    RUNNING: 'running',
    FINISHED: 'finished',
    ERROR: 'error',
  },
};

const chats = {
  getChatList: () => {
    return api.get('/chats');
  },
  // getChatMessages: () => {
  getChatMessages: (id) => {
    return api.get(`/chats/${id}`);
    // return Promise.resolve({
    //   data: chatCOAIMessagesArray,
    // });
  },
  getChatStatus: (id) => {
    return api.get(`/chats/${id}/status`);
  },
  finishChatThread: (id) => {
    return api.post(`/chats/${id}/close_ai_chat`);
  },
  joinToChat: (id) => {
    return api.post(`/chats/join/${id}`);
  },
  sendMessage: ({ id, text }) => {
    return api.post(`/chats/${id}/send_message`, {
      chat_id: id,
      text,
    });
  },
  sendFileMessage: async ({ id, file }) => {
    const savedFileResponse = await quarantine.uploadFileAndWaitForStatus({ file });
    return api.post(`/chats/${id}/send_file_message`, {
      attachment: { file_id: savedFileResponse.data.id },
    });
  },
  getFile: (id) => {
    return api.get(`/chats/files/${id}/download_path`);
  },
};

const quarantine = {
  uploadFileAndWaitForStatus: async ({ file }) => {
    const uploadFileResponse = await quarantine.uploadFile({ file });
    return quarantine.waitForScanStatus({ fileId: uploadFileResponse.data.id });
  },
  uploadFile: ({ file }) => {
    const data = new FormData();
    data.append('attachment[file]', file);
    return api.post('/quarantine/files', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  checkUploadStatus: ({ fileId }) => {
    return api.get(`/quarantine/files/${fileId}`);
  },
  waitForScanStatus: ({ fileId }) => {
    let resolveStatus;
    let rejectStatus;

    const waitForScanStatusChange = async () => {
      const statusResponse = await quarantine.checkUploadStatus({ fileId });
      switch (statusResponse.data.status) {
        case 'ok':
          resolveStatus(statusResponse);
          break;
        case 'virus_found':
          rejectStatus();
          break;
        case 'scan_failed':
          rejectStatus();
          break;
        default:
          setTimeout(() => waitForScanStatusChange(), 1000);
      }
    };

    waitForScanStatusChange();

    return new Promise((resolve, reject) => {
      resolveStatus = resolve;
      rejectStatus = reject;
    });
  },
};

const config = {
  getConfig: (language = '') => {
    return api.get(appendQueryParams(`/config`, { language }));
  },
  getRulesTemplate: () => {
    return api.get('/surveys/questions?type[]=select&type[]=radio');
  },
  getCustomConfig: () => {
    return configFile;
  },
  getTranslation: (locale = '') => {
    const apiURL = locale
      ? `/translations/translations?language=${locale}`
      : '/translations/translations';
    return api.get(apiURL);
  },
  getSurvey: (surveyPath) => {
    return api.get(`/surveys/${surveyPath}`);
  },
};

export const isSessionTimeout = (error) =>
  error.response.status === 401 && error.response.data.error === 'Session timed out';

export const isUnauthorized = (error) =>
  error.response.status === 401 && error.response.data.error === 'Unauthorized';

export const isForbidden = (error) =>
  error.response.status === 403 && error.response.data.error === 'Forbidden';

export const isExpiredPassword = (error) => error.response.status === 419;

export function addApiInterceptor(onSuccess, onError) {
  api.interceptors.response.use(
    (res) => {
      onSuccess && onSuccess(res);

      return Promise.resolve(res);
    },
    (error) => {
      onError && onError(error);

      return Promise.reject(error);
    }
  );
}

function extractId(object) {
  return object.id;
}

export default {
  instance: api,
  auth,
  me,
  coDashboard,
  userDashboard,
  extendedUserDashboard,
  documents,
  users,
  misconduct,
  groups,
  categories,
  imports,
  notifications,
  search,
  chats,
  config,
  abortController,
};
