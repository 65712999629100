import React from 'react';
import PropTypes from 'prop-types';

import { avatarSize, StyledAIAvatar } from './style';

const propTypes = {
  size: PropTypes.string.isRequired,
};

const AIAvatar = ({ size }) => {
  return <StyledAIAvatar size={size}>AI</StyledAIAvatar>;
};

AIAvatar.propTypes = propTypes;

export default AIAvatar;
export { avatarSize };
