import React from 'react';
import PropTypes from 'prop-types';
import { avatarSize } from 'components/UserAvatar';

import { MessageWrapper, ContentWrapper, DotWrapper, Dot, Text } from '../style';
import AIAvatar from 'components/AIAvatar';

const propTypes = {
  isOutcoming: PropTypes.bool,
  message: PropTypes.string,
};

const TypingDotsMessage = ({ isOutcoming, message }) => {
  return (
    <MessageWrapper isOutcoming={isOutcoming}>
      <AIAvatar size={avatarSize.MEDIUM} />

      <ContentWrapper isOutcoming={isOutcoming}>
        {message && <Text isOutcoming={isOutcoming}>{message}</Text>}
        <DotWrapper>
          <Dot delay="0s" />
          <Dot delay=".1s" />
          <Dot delay=".2s" />
        </DotWrapper>
      </ContentWrapper>
    </MessageWrapper>
  );
};

TypingDotsMessage.propTypes = propTypes;

export default TypingDotsMessage;
