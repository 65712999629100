import { combineReducers } from 'redux';
import { clearStorageAndCookies } from 'helpers';

import auth, { RESET_ROOT_REDUCER, initialState } from './auth';
import me from './me';
import userDashboard from './userDashboard';
import coDashboard from './coDashboard';
import users from './users';
import reports from './reports';
import groups from './groups';
import categories from './categories';
import groupDetails from './groupDetails';
import userDetails from './userDetails';
import importData from './importData';
import toastMessages from './toastMessages';
import checkMisconductStatus from './checkMisconductStatus';
import misconductForm from './misconductForm';
import anonymousMisconductForm from './anonymousMisconductForm';
import officerMisconductForm from './officerMisconductForm';
import groupUsers from './groupUsers';
import addingGroup from './addingGroup';
import notifications from './notifications';
import allDocuments from './allDocuments';
import userDocuments from './userDocuments';
import userMisconducts from './userMisconducts';
import documentPreview from './documentPreview';
import documentDetails from './documentDetails';
import documentEdit from './documentEdit';
import documentLogs from './documentLogs';
import documentVersions from './documentVersions';
import newDocumentVersion from './newDocumentVersion';
import specificUserDocuments from './specificUserDocuments';
import reportDetails from './reportDetails';
import recipientsGroups from './recipientsGroups';
import allRecipients from './allRecipients';
import chatrooms from './chatrooms';
import tenantProperties from './tenantProperties';
import survey from './survey';

const appReducer = combineReducers({
  auth,
  me,
  userDashboard,
  coDashboard,
  users,
  reports,
  reportDetails,
  groups,
  categories,
  groupDetails,
  userDetails,
  importData,
  toastMessages,
  documentEdit,
  checkMisconductStatus,
  misconductForm,
  anonymousMisconductForm,
  officerMisconductForm,
  groupUsers,
  addingGroup,
  notifications,
  allDocuments,
  userDocuments,
  userMisconducts,
  documentPreview,
  documentDetails,
  documentLogs,
  documentVersions,
  newDocumentVersion,
  recipientsGroups,
  specificUserDocuments,
  allRecipients,
  chatrooms,
  tenantProperties,
  survey,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_ROOT_REDUCER) {
    const { auth, tenantProperties } = state;
    state = {
      auth: {
        ...initialState,
        userLanguage: auth.userLanguage,
      },
      tenantProperties,
    };

    clearStorageAndCookies();
  }

  return appReducer(state, action);
};

export default rootReducer;
