import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { isAuthenticatedOfficer, logInUser, updateUserLanguage } from 'stores/auth';
import { resolveDashboardRoute, ROUTING } from 'routing';

import { EMPLOYEE_SCOPE } from 'helpers/sharedConstants';

import EntryWrapper from 'components/EntryWrapper';
import Typography from 'components/Typography';
import Button from 'components/Button';
import LoginInput from 'components/LoginInput';
import LoginHeader from 'components/LoginHeader';
import LanguageSelectBanner from 'components/LanguageSelectBanner';
import { AlertNotification } from 'components/Notification';

import { InputGroup, ForgotPasswordLink, LoginWrapper } from './style';
import { LoginMiddleSectionForm } from 'styles/commonStyles';
import { globalMessages } from 'translations';
import { arelanguagesFromMisconducts } from 'helpers/functions';
import { useIntl } from 'react-intl';
import { messages } from './messages';
import LandingHeader from '../LandingPage/LandingHeader';

export const Login = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    properties: { misconductLanguages, customConfig },
  } = useSelector((state) => state.tenantProperties);
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, userLanguage, isLoading } = auth;

  const [selectedLanguage, setLanguage] = useState();
  const [error, setError] = useState();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const selectLanguage = (selectedLanguage) => {
    setLanguage({ selectedLanguage });
  };

  const confirmLanguageSelection = () => {
    dispatch(updateUserLanguage(selectedLanguage));
  };

  const submitForm = async (evt) => {
    evt.preventDefault();

    try {
      await dispatch(logInUser({ login, password, scope: EMPLOYEE_SCOPE }));
    } catch (error) {
      setError(error.response.data.error);
    }
  };
  const dashboardRoute = resolveDashboardRoute(isAuthenticatedOfficer(auth));

  if (isAuthenticated) {
    if (userLanguage) return <Redirect to={dashboardRoute} />;
    return (
      <LanguageSelectBanner
        availableLanguages={
          arelanguagesFromMisconducts(customConfig) ? misconductLanguages : undefined
        }
        selectedLanguage={selectedLanguage}
        changeHandler={selectLanguage}
        isLoading={isLoading}
        onConfirmationClick={confirmLanguageSelection}
        description={intl.formatMessage(globalMessages.chooseLanguage)}
      />
    );
  }

  return (
    <EntryWrapper withFooter>
      <LoginHeader />

      <LoginWrapper>
        <LoginMiddleSectionForm onSubmit={submitForm} autocomplete="off">
          {error && <AlertNotification>{error}</AlertNotification>}
          <LandingHeader
            headerText={intl.formatMessage(messages.hello)}
            descriptionText={intl.formatMessage(messages.loginReportingHeader)}
          />
          <InputGroup>
            <LoginInput
              type="email"
              autocomplete="new-password"
              label={intl.formatMessage(globalMessages.email)}
              placeholder={intl.formatMessage(globalMessages.email)}
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />

            <LoginInput
              type="password"
              autocomplete="current-password"
              label={intl.formatMessage(globalMessages.password)}
              placeholder={intl.formatMessage(globalMessages.password)}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputGroup>

          <Typography
            component={ForgotPasswordLink}
            to={ROUTING.FORGOT_PASSWORD}
            size="small"
            weight="bold"
            textAlign="right"
          >
            {intl.formatMessage(globalMessages.forgotPassword)}
          </Typography>

          <Button isProcessing={isLoading} primary isFluid type="submit">
            {intl.formatMessage(globalMessages.login)}
          </Button>
        </LoginMiddleSectionForm>
      </LoginWrapper>
    </EntryWrapper>
  );
};

export default Login;
