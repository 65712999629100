import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { ROUTING } from 'routing';
import AuthRoute from 'components/AuthRoute';
import { hasRoleRights } from '../../stores/auth';
import PropTypes from 'prop-types';

const propTypes = {
  modules: PropTypes.array,
};

export const EmployeeRoute = ({ modules, ...props }) => {
  const { isCo, roles } = useSelector((state) => state.auth);
  const isEmployee = !isCo;

  const hasRole = modules ? hasRoleRights(roles, modules) : true;

  return isEmployee && hasRole ? (
    <AuthRoute {...props} />
  ) : (
    <Redirect to={ROUTING.INDEX} />
  );
};

EmployeeRoute.propTypes = propTypes;

export default EmployeeRoute;
