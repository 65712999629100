import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { CHAT_FILETYPES_ALLOWED, FILESIZE_20MB } from 'helpers/sharedConstants';

import SendIcon from 'components/Svg/SendIcon';
import FileUploader from 'components/FileUploader';
import SingleSkeleton from 'components/Skeletons/SingleSkeleton';

import { colorName } from 'styles';
import { InputWrapper, IconsWrapper, FileLoader, IconButton, Input } from './style';
import { messages } from './messages';

const defaultPropTypes = {
  areUploadsAllowed: false,
};

const propTypes = {
  placeholder: PropTypes.string,
  isDark: PropTypes.bool,
  areUploadsAllowed: PropTypes.bool.isRequired,
  sendHandler: PropTypes.func.isRequired,
  uploadHandler: PropTypes.func.isRequired,
  uploadingInProgress: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  aiTyping: PropTypes.bool,
};

const useAutosizeTextArea = (textAreaRef, value) => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = '0px';
      const scrollHeight = textAreaRef.scrollHeight;
      textAreaRef.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, value]);
};

export const ChatMessageInput = ({
  placeholder,
  isDark,
  areUploadsAllowed,
  sendHandler,
  uploadHandler,
  uploadingInProgress,
  readOnly,
  aiTyping,
}) => {
  const intl = useIntl();
  const [messageText, setMessageText] = useState('');
  const textAreaRef = useRef(null);

  useAutosizeTextArea(textAreaRef.current, messageText);

  const onChange = useCallback(
    (evt) => setMessageText(evt.target.value),
    [setMessageText]
  );

  const onSubmit = useCallback(
    (evt) => {
      evt.preventDefault();

      sendHandler(messageText);
      setMessageText('');
    },
    [sendHandler, messageText, setMessageText]
  );

  const uploadFiles = useCallback((files) => uploadHandler(files[0]), [uploadHandler]);

  return (
    <InputWrapper onSubmit={onSubmit}>
      <Input
        isDark={isDark}
        placeholder={placeholder || intl.formatMessage(messages.chatPlaceholder)}
        onChange={onChange}
        value={messageText}
        disabled={uploadingInProgress || readOnly}
        required
        ref={textAreaRef}
      />

      <IconsWrapper isDark={isDark}>
        {areUploadsAllowed && !readOnly && (
          <FileUploader
            isChatUploader
            maxNumOfFiles={1}
            filesProps={{
              files: [],
            }}
            dropzoneProps={{
              fileTypes: CHAT_FILETYPES_ALLOWED,
              acceptableHint: '.jpg, .png, .docx, .xls, .pdf, audio',
              maxSize: FILESIZE_20MB,
              addFile: uploadFiles,
            }}
          />
        )}

        {!readOnly && (
          <IconButton
            disabled={aiTyping}
            aria-label={intl.formatMessage(messages.sendMessageButton)}
          >
            <SendIcon />
          </IconButton>
        )}
      </IconsWrapper>

      {uploadingInProgress && (
        <FileLoader>
          <SingleSkeleton
            color={colorName.primary}
            highlightColor={colorName.white}
            height={3}
          />
        </FileLoader>
      )}
    </InputWrapper>
  );
};

ChatMessageInput.defaultProps = defaultPropTypes;
ChatMessageInput.propTypes = propTypes;
